@import '../../../variables.scss';

.GuildsTable {
    
}

.GuildsTable__searchResults {
    padding: 40px 0 20px;
}

.GuildsTable__sortBox {
    margin-bottom: 20px;
 }

.GuildsTable__sortFields {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: $light-grey-opaque-70;
    padding: 15px;
}

.GuildsTable__radioButtons {
    width: 100%;
    padding-top: 10px;
}

.GuildsTable__button {
    width: 100%;
    display: flex;
    justify-content: center;
}

.GuildsTable__rows {
    padding: 0 0 40px;
}

.GuildsTable__row {
    cursor: pointer;
    margin: 10px 0;
    padding: 20px 30px;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    transition: $quick-ease;
}

.GuildsTable__row:hover {
    background-color: $light-grey;
}

.GuildsTable__rowGeneral {
    display: flex;
}

.GuildsTable__rowMembers {
    display: none;
    font-size: $size-16-20;
    line-height: $size-20-24;
    color: $secondary-font-color;
    text-align: center;
}

.GuildsTable__rowEnd {
    align-self: center;
    justify-self: end;
}

.GuildsTable__rowStatus {
    display: none;
    font-size: $size-20-24;
    line-height: $size-22-28;
}

.GuildsTable__arrow {
    font-size: 32px;
    margin-left: 20px;
    color: $gold;
}

.GuildsTable__arrow:hover {
    transform: scale(1.15);
}

.GuildsTable__guildNames {
    font-weight: bold;
}

.GuildsTable__displayName {
    color: $secondary-font-color;
    font-size: $size-20-24;
    word-break: break-all;
    line-height: $size-22-28;
    padding-bottom: 8px;
}

.GuildsTable__name {
    color: $minor-font-color;
    font-size: $size-16-20;
}

.GuildsTable__tag {
    padding-left: 2px;
    line-height: $size-20-24
}

.GuildsTable__row--open {
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.GuildsTable__openRowHeader {
    margin-top: 20px;
    padding: 10px 30px 10px 0;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    transition: $quick-ease;
}

.GuildsTable__openRowHeader:hover {
    background-color: $light-grey;
}


@media (min-width: 600px) {

    .GuildsTable__radioButtons {
        order: 3;
    }
    
    .GuildsTable__button {
        order: 2;
        width: auto;
    }

    .GuildsTable__guildNames {
        padding-right: 20px;
    }

    .GuildsTable__rowGeneral {
        align-self: center;
    }

    .GuildsTable__rowEnd {
        display: flex;
    }

    .GuildsTable__rowStatus {
        display: block;
    }

}

@media (min-width: 900px) {

    .GuildsTable__sortBox {
       display: flex;
       justify-content: flex-end;
    }

    .GuildsTable__sortFields {
        width: auto;
        justify-content: center;
    }

    .GuildsTable__field {
        padding-right: 20px;
    }

    .GuildsTable__radioButtons {
        order: 2;
        width: auto;
        padding: 0 20px 0 0;
    }

    .GuildsTable__button {
        order: 3;
    }

    .GuildsTable__row {
        grid-template-columns: 1fr auto 1fr;
    }

    .GuildsTable__guildCrest {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    .GuildsTable__rowMembers {
        display: block;
        align-self: center;
        justify-self: center;
    }
}