@import "../../../variables.scss";

.ECSSponsorsPage {
    color: $primary-font-color;
    font-size: 18px;
    padding: 0 20px;
}

.ECSSponsorsPage__tagline {
    text-align: center;
    margin-bottom: 30px;
}

.ECSSponsorsPage__body {
    margin-bottom: 20px;
}