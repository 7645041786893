@import '../../variables.scss';

.ValidateEmailPage {
    width: 100%;
}

.ValidateEmailPage__banner {
    width: 100%;
    height: 550px;
    background-size: cover;
    background-position: center;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .3);
    background-blend-mode: lighten;
    margin-bottom: 0;
    font-size: 20px;
    text-align: center;
}

.ValidateEmailPage__bannerContent {
    width: 100%;
}

.ValidateEmailPage__bannerContent h1 {
    color: #CC885E;
    font-size: calc(1.8em + 1vw);
    text-shadow: rgba(0,0,0,0.2) 1px 2px 6px;
    filter: drop-shadow(4px 2px 2px black);
}

.ValidateEmailPage__bannerContent p {
    font-size: calc(1em + 1vw);
    color: #fff;
    filter: drop-shadow(2px 6px 6px black);
}

.ValidateEmailPage__storeCards {
    margin-bottom: 40px;
}

.ValidateEmailPage__storeCardButton {
    float: left;
    margin: 5px 0 10px;
}

.ValidateEmailPage__thankBackersText {
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
    padding: 0 15px;
}

.ValidateEmailPage__storePriceDisclaimer {
    padding: 0 15px;
}

.ValidateEmailPage__socialText {
    width: 100%;
    text-align: center;
    padding: 0 15px;
    font-size: 24px;
}

.ValidateEmailPage__socialLinks {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ValidateEmailPage__socialLink {
    padding: 0 5px;
    filter: $h3-drop-shadow
}

.TravianEvent {
    display: 'none';
}

@media(min-width: 900px) {
	.ValidateEmailPage__banner {
        height: 700px;
        padding: 0 30px;
    }

    .ValidateEmailPage__bannerContent h1 {
        font-size: calc(2em + 1vw);
    }
}

