@import "../../../../variables.scss";

.LanguageSelector {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    color: #c3c3c3;
    font-size: 18px;
    position: relative;
}

.LanguageSelector__content {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
}

.LanguageSelector__content:hover {
    color: white;
}

.LanguageSelector__language {
    padding: 0 5px;
}

.LanguageSelector__options {
    display: none;
}

.LanguageSelector--open {
    .LanguageSelector__options {
        display: block;
        border: 1px solid #c3c3c3;
        position: absolute;
        top: 40px;
        min-width: 200px;
        background-color: rgb(8, 9, 15);
        border-radius: 5px;
        padding: 5px 0;
    }

    .LanguageSelector__option {
        text-align: left;
        padding: 7px 10px;
        cursor: pointer;
        font-size: 20px;
        color: #c3c3c3;
    }

    .LanguageSelector__option:hover {
        background-color: rgb(20, 20, 20);
        color: white;
    }
}