.MarketingFooter {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 20px;
	padding: 20px 0 0 0;
	align-items: center;
	justify-items: center;
}


@media(min-width: 600px) {

    .MarketingFooter {
		display: flex;
		justify-content: space-between;
    	padding: 20px 20px 0;
        align-items: inherit;
    }
}