.GuildCrest {
	width: 190px;
	height: 190px;
	position: relative;
}

.GuildCrest__recruiting {
	display: block;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -22px;
    left: 50%;
    right: 0;
    width: 184px;
    height: 33px;
    padding: 0 20px;
    margin: 0 0 0 -92px;
    color: #000;
    font-size: 11px;
    line-height: 13px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}

.GuildCrest__recruiting-ribbon {
}

.GuildCrest__recruiting-label {
	position: absolute;
}

.GuildCrest--large {
	width: 270px;
    height: 270px;
    
    .GuildCrest__recruiting {
        transform: scale(1.2);
    }
}

.GuildCrest--small {
	width: 50px;
	height: 50px;
}

.GuildCrest--recruiting {
    margin-bottom: 30px;
}