.SearchResultsDisplay {
    width: 100%;
    text-align: center;
    font-size: 18px;
}

.SearchResultsDisplay__noResultsText {
    padding-top: 20px;
}

.SearchResultsDisplay__resultsCount {
    font-weight: bold;
}

.SearchResultsDisplay__disabledText {
    font-size: 20px;
}

.SearchResultsDisplay--disabled {

    .SearchResultsDisplay__resultsCount {
        display: none;
    }

    .SearchResultsDisplay__noResultsText {
        display: none;
    }

}