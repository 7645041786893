.ECSFooter {
    background-color: #08090f;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 30px 20px 20px;
}

.ECSFooter__img {
    margin: 30px 60px;
}