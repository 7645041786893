@import '../../../variables.scss';

.GuildBankPage {
    color: $primary-font-color;
}

.GuildBankPage__balance {
    font-size: $size-20-24;
    color: $marketing-blue;
    text-align: center;
    padding: 20px 20px 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.GuildBankPage__walletOptions {
    padding-bottom: 50px;
}

.GuildBankPage__productCards {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.GuildBankPage__card {
    padding: 20px;
}

.GuildBankPage__transactionTable {
    padding-top: 30px;
}