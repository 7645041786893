.ui.cards>.card>.content {
    font-size: 16px;
}

.ui.cards>.card .meta {
    font-size: 16px;
}

.ui.button.text-only:hover {
    opacity: .9;
    color: #2185d0!important;
}

.ui.button.Button__ACE, .ui.button.Button__ACE:hover, .ui.button.Button__ACE:active {
    border-radius: 10px;
    background-color: #30556b;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    padding: 12px 18px;
    line-height: 1;
    font-weight: 700;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.38);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: white;
}

.ui.button.Button__ACE.Button_ACE-secondary, .ui.button.Button__ACE.Button_ACE-secondary:hover {
    background-color: rgb(224, 225, 226);
    color: black;
}

.ui.button.store,.ui.button.store:hover {
    padding: 5px;
    border-radius: 10px;
    background-color: #30556b;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    padding: 12px 18px;
    line-height: 1;
    font-weight: 700;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.38);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: white;
}

.ui.button:hover .icon {
    opacity: 1;
}

.ui.button.Button__ACE-large, .ui.button.Button__ACE-large:hover {
    font-size: 30px;
    padding: 18px 26px;
}


.ui.statistic>.value {
    font-size: calc(1.5em + 1vw);
}

.ui.dropdown .menu .item, .ui.dropdown .menu .active.item {
    z-index: 99999;
}

.ui.card .meta, .ui.cards>.card .meta {
    color: rgb(0,0,0);
}

.ui.items>.item .meta {
    color: rgb(0,0,0);
}

a {
    color: steelblue;
}
