.FaqQuestionPage {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.FaqQuestionPage__backButton {
    padding-bottom: 25px;
    width: 100%;
}

.FaqQuestionPage__backButtonText {
    color: black;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.FaqQuestionPage__backButtonArrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 10px 6px 0;
    border-color: transparent black transparent transparent;
    margin-right: 5px;
}

.FaqQuestionPage__content {
    max-width: 1200px;
    padding: 0 15px;
}

.FaqQuestionPage__contentBox {
    padding-top: 20px;
}

.FaqQuestionPage__questionAndAnswer {
    padding: 5px 10px;
}

.FaqQuestionPage__question {
    font-weight: bold;
    width: 100%;
    font-size: 16px;
    padding-bottom: 20px;
}

.FaqQuestionPage__answer {
    width: 100%;
}

.FaqQuestionPage__emailUs {
    padding: 50px 0 10px;
    width: 100%;
    text-align: center;
}

@media (min-width: 600px) {
    .FaqQuestionPage__questionAndAnswer {
        padding: 10px 20px;
    }

    .FaqQuestionPage__question {
        font-size: 18px;
        padding-bottom: 30px;
    }

    .FaqQuestionPage__answer {
        font-size: 16px;
    }
}

@media (min-width: 1200px) {
    .FaqQuestionPage__questionAndAnswer {
        padding: 20px 30px;
    }
}