@import "../../../variables.scss";

.GuildApplicationsPage {

}

.GuildApplicationsPage__pendingApplications {
    padding: 0 0 50px;
}

.GuildApplicationsPage__noApplicationsText {
    padding: 20px 20px 0;
    text-align: center;
    font-size: 18px;
}

.GuildApplicationsPage__applicationsTable {
    padding-top: 20px;
}

.GuildApplicationsPage__application {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.GuildApplicationsPage__applicationHeader {
    font-weight: bold;
    padding-bottom: 10px;
    width: 100%;
}

.GuildApplicationsPage__applicationMessage {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    white-space: pre-wrap;
    max-height: 160px;
    overflow-y: auto;
    border: 1px solid $light-med-grey;
    background-color: $light-grey;
    border-radius: 4px;
}

.GuildApplicationsPage__applicationEmptyMessage {
    width: 100%;
    margin-bottom: 10px;
    color: $secondary-font-color;
}

.GuildApplicationsPage__applicationButtons {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 15px;
}

.GuildApplicationsPage__notAcceptingText {
    padding-top: 30px;
    text-align: center;
}

.GuildApplicationsPage__bannedUsers {
    padding-top: 20px;
}