@import "../../../variables.scss";

.GiftingPage {

}

.GiftingPage__headerText {
    text-align: center;
    padding: 20px 0 40px;
    font-size: $size-16-20;
    line-height: $size-20-24; 
}

.GiftingPage__header {
    padding-bottom: 30px;
}

.GiftingPage__section {
    padding-bottom: 40px;
}

.GiftingPage__subheader {
    padding-bottom: 15px;
}