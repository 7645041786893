@import "../../../variables.scss";

.FieldInput {
    width: 100%;
}

.FieldInput__label {
    font-size: 16px;
    padding-bottom: 8px;
    overflow-wrap: anywhere;
}

.FieldInput__sublabel {
    font-size: 14px;
    padding-bottom: 4px;
}

.FieldInput__box {
	width: 100%;
    font-size: 16px;
	padding: 10px;
	border: 1px solid $medium-grey;
	background-color: $light-grey;
    height: 100%;
    cursor: pointer;
}

.FieldInput__box:focus {
	outline: 1px solid $dark-grey;
}

.FieldInput--area {
    .FieldInput__box {
        resize: none;
    	height: 140px;
    }
}

.FieldInput--areaShort {
    .FieldInput__box {
    	height: 90px;
    }
}

.FieldInput--areaTall {
    .FieldInput__box {
    	height: 200px;
    }
}

.FieldInput--areaXTall {
    .FieldInput__box {
    	height: 300px;
    }
}

.FieldInput--inline {
    display: flex;
    align-items: center;

    .FieldInput__label {
        padding-right: 10px;
        max-width: 50%;
        padding-bottom: 0;
    }
}

.FieldInput--disabled {
    .FieldInput__box {
        cursor: default;
        color: $tertiary-font-color;
    }
}


@media (min-width: 600px) {
    .FieldInput--inline {
        .FieldInput__box {
            width: 350px;
        }
    }

    .FieldInput--area {
        .FieldInput__box {
            height: 80px;
        }
    }
    
    .FieldInput--areaShort {
        .FieldInput__box {
            height: 60px;
        }
    }
    
    .FieldInput--areaTall {
        .FieldInput__box {
            height: 150px;
        }
    }

    .FieldInput--areaXTall {
        .FieldInput__box {
            height: 300px;
        }
    }
}


@media (min-width: 900px) {
    .FieldInput--columnal {
        display: flex;
        align-items: center;

        .FieldInput__label {
            width: 220px;
            padding: 0 20px 0 0;
        }

        .FieldInput__mainContent {
            width: auto;
            flex-grow: 1;
        }

        .FieldInput__sublabel {
            padding-bottom: 0;
        }
    }

    .FieldInput--areaXTall {
        .FieldInput__box {
            height: 350px;
        }
    }
}