.TeamMemberAsset {
    width: 160px;
    margin: 15px;
    position: relative;
    font-weight: bold;
    color: white;
    position: relative;
}

.TeamMemberAsset__bannerImg {
    position: absolute;
    top: -10px;
    left: -15px;
    width: 190px;
}

.TeamMemberAsset__bannerText {
    position: absolute;
    top: -8px;
    width: 100%;
    color: white;
    text-align: center;
    font-size: 18px;
}

.TeamMemberAsset__asset {
    width: 160px;
}

.TeamMemberAsset__username {
    width: 100%;
    position: absolute;
    bottom: 3px;
    font-size: 16px;
    text-align: center;
}