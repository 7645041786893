@import "../../../variables.scss";

.CrownAmount {
	display: flex;
	align-items: center;
}

.CrownAmount__label {
	white-space: nowrap;
}

.CrownAmount__icon {
    padding-left: 10px;
}

.CrownAmount--flipped {

	.CrownAmount__icon {
		order: -1;
		padding: 0 10px 0 0;
	}
}

.CrownAmount--negative {
	color: red;
}

.CrownAmount--orange {
	color: $medium-orange
}

.CrownAmount--guild {
	color: $marketing-blue
}
