@import '../../../variables.scss';

.PromoBanner {
	padding: 60px 5%;
    width: 100%;
    height: 100%;
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: $standard-box-shadow;
}

.PromoBanner__title {
    position: relative;
	font-size: 30px;
    color: #d48d31;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
    line-height: 1.25;
    filter: $h1-drop-shadow;
}

.PromoBanner__titleBlur {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    @include BlurBackgroundEffect(50px);
}

.PromoBanner__description {
	color: #ffffff;
    font-size: 20px;
    line-height: 1.25;
    margin-bottom: 20px;
    filter: $h3-drop-shadow;
    max-width: 700px;
}


.PromoBanner__buttons {
	display: flex;
}

.PromoBanner__button {
	margin-right: 15px;
}