.ArticleList {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	cursor: pointer;
	width: 100%;
}

.ArticleList__articles {
	width: 100%;
	display: grid;
}

.ArticleList__article {
	position: relative;
	width: 100%;
	padding-bottom: 20px;
}

.ArticleList__load {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.ArticleList__loadInner {
	background-color: rgba(48,85,107,0.9);
	width: 100%;
	height: 100%;
    font-size: 16px;
    font-family: "Open Sans Condensed",Arial,Helvetica,sans-serif;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 900px) {
	.ArticleList__articles {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
	}
	
	.ArticleList__article {
		align-self: stretch;
		padding-bottom: 0;
	}
}

@media (min-width: 1200px) {
	.ArticleList__articles {
		grid-template-columns: repeat(6, 1fr);
	}

	.ArticleList__article:first-child {
		grid-column-start: 1;
		grid-column-end: span 3;
	}
	
	.ArticleList__article:nth-child(2) {
		grid-column-start: 4;
		grid-column-end: span 3;
	}
	
	.ArticleList__article:nth-child(n + 3) {
		grid-column-end: span 2;
	}
}
