.BlogsPage {
    padding: 30px 20px;
}

.BlogsPage__tags {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 20px 0;
}

.BlogsPage__tagPill {
    padding-left: 10px;
}

.BlogsPage__searchbar {
    padding: 10px 0 30px;
    display: flex;
    justify-content: flex-end;
}

.BlogsPage__searchField {
    width: 100%;
}

.BlogsPage__blogs {
    padding: 10px 0 30px;
}

@media (min-width: 600px) {
    .BlogsPage__searchField {
        max-width: 500px;
    }
}