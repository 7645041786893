@import '../../../variables.scss';

.PageNotFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
}

.PageNotFound__header {
    font-weight: bold;
    color: $primary-font-color;
    font-size: calc(3em + 1vw);
    line-height: calc(2em + 1vw);
    padding-bottom: 20px;
}

.PageNotFound__subheader {
    color: $secondary-font-color;
    font-size: calc(1.6em + 1vw);
    line-height: calc(1.2em + 1vw);
    padding-bottom: 30px;
}