@import '../../../variables.scss';

.VerticalMenu {
    position: relative;
}

.VerticalMenu__header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    background-color: $medium-grey;
    cursor: pointer;
    color: $secondary-font-color;
    font-size: 16px;
    position: relative;
    z-index: 10;
}

.VerticalMenu__header:hover {
    background-color: $medium-dark-grey;
}

.VerticalMenu__label {
    padding-left: 8px;
    font-weight: bold;
}

.VerticalMenu__overlay {
    display: none;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, .15);
    z-index: 9;
}

.VerticalMenu__menu {
    display: none;
    position: absolute;
    top: 50px;
    width: 100%;
    z-index: 10; 
}

.VerticalMenu--isOpen {
    .VerticalMenu__overlay {
        display: block;
    }

    .VerticalMenu__menu {
        display: block;
    }
}

.VerticalMenu__menu {
    border: 1px solid $minor-font-color;
    box-shadow: $standard-box-shadow;
    background-color: white;
}

.VerticalMenu__section {
    border-bottom: .75px solid $minor-font-color;
}

.VerticalMenu__row {
    position: relative;
    width: 100%;
}

.VerticalMenu__rowLabel {
    font-size: 16px;
    font-weight: bold;
    padding: 12px 20px 8px;
    width: 100%;
    text-align: center;
    color: $secondary-font-color;
    display: flex;
    justify-content: center;
}

.VerticalMenu__rowCount {
    height: 20px;
    min-width: 20px;
    border-radius: 10px;
    margin-right: 5px;
    background-color: $medium-orange;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
}

.VerticalMenu__rowToggle {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 35px 0 15px;
    font-size: 16px;
    display: flex;
    align-items: center;
    background-color: white;
    border-left: 1px solid $minor-font-color;
}

.VerticalMenu__subs {
    padding-bottom: 8px;
}

.VerticalMenu__subrow {
    color: $secondary-font-color;
    font-size: 14px;
    font-weight: 400;
    padding: 7px 20px;
    display: flex;
    justify-content: center;
}

.VerticalMenu__subrowCount {
    height: 20px;
    min-width: 20px;
    border-radius: 20px;
    margin-right: 5px;
    background-color: $medium-orange;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
}

.VerticalMenu:last-child {
    border-bottom: none;
}

.VerticalMenu__rowLabel {
    cursor: default;
}

.VerticalMenu__rowToggle:hover {
    color: $primary-font-color;
    background-color: $light-grey;
}

.VerticalMenu__section--active {
    .VerticalMenu__rowLabel {
        background-color: $light-med-grey;
    }
}

.VerticalMenu__section--closed {
    .VerticalMenu__row {
        border-bottom: 1px solid $minor-font-color;
    }
}

.VerticalMenu__section--clickable {
    .VerticalMenu__row {
        cursor: pointer;
    }

    .VerticalMenu__rowLabel {
        padding-bottom: 12px;
    }

    .VerticalMenu__rowLabel:hover {
        color: $primary-font-color;
        cursor: pointer;
        background-color: $light-grey;
    }

    .VerticalMenu__subs {
        border-top: 1px solid $minor-font-color;
        padding-top: 8px;
    }
}

.VerticalMenu__subrow:hover {
    color: $primary-font-color;
    background-color: $light-grey;
}

.VerticalMenu__subrow--active {
    background-color: $light-med-grey;
}

@media (min-width: 1200px) {

    .VerticalMenu__header {
        display: none;
    }

    .VerticalMenu__overlay {
        display: none;
    }

    .VerticalMenu__menu {
        display: block;
        position: initial;
    }

    .VerticalMenu__row {
        display: grid;
        grid-template-columns: 1fr auto;
    }

    .VerticalMenu__rowLabel {
        text-align: left;
        padding-right: 5px;
        justify-content: flex-start;
    }

    .VerticalMenu__rowToggle {
        position: initial;
        padding: 0 15px;
    }

    .VerticalMenu__subrow {
        padding-left: 35px;
        justify-content: flex-start;
    }

    .VerticalMenu--isOpen {
        .VerticalMenu__overlay {
            display: none;
        }
    }
}