.PressPage__kit {
	margin-bottom: 40px;
	padding: 0 20px;
}

.PressPage__subtitle {
	text-align: center;
	margin: 40px;
}

.PressPage__kitBody {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.PressPage__download {
	padding: 40px;
	img {
		height: 30px;
		filter: invert(100%);
	}
}

.PressPage__downloadButton {
	margin: 20px 0;
}

.PressPage__email {
	display: block;
}

.PressPage__releases {
	margin-top: 20px;
	padding: 0 20px;
}

.PressPage__release {
	margin-bottom: 80px;
}

.PressPage__release_title {
	font-size: calc(2em);
	line-height: 1em;
}

.PressPage__release_tagline {
	font-size: calc(1.3em);
	color: rgb(100,100,100);
	font-style: italic;
	margin: 5px 0 20px 0;
}

@media(min-width: 1200px) {
	.PressPage__kitBody {
		flex-direction: row-reverse;
		align-items: flex-start;
	}
}
