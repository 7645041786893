.RegistrationCheckoutPage {
    padding-bottom: 20px;
}

.RegistrationCheckoutPage p {
    font-size: 18px;
}

.RegistrationCheckoutPage__header {
    font-weight: bold;
    width: 100%;
    padding-bottom: 20px;
    font-size: 1.8rem;
    line-height: 2rem;
}