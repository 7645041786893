@import '../../../variables.scss';

.Tag {
    cursor: default;
    padding: 5px 10px;
    width: min-content;
    white-space: nowrap;
    background: $secondary-font-color;
    border-radius: 5px;
    color: #fff;
    box-shadow: $standard-box-shadow;
}

.Tag--selectable {
    cursor: pointer;
    background: $medium-blue-opaque;
    color: $secondary-font-color;
}

.Tag--selectable:hover {
    transform: scale(1.05);
}