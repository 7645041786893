.BackToECSButton {
    display: flex;
    justify-content: center;
    width: 100%;
}

@media(min-width: 600px) {
    .BackToECSButton {
        justify-content: flex-start;
    }
}