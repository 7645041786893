@import '../../../variables.scss';

.DisplayField {
    color: $primary-font-color;
}

.DisplayField__label {
    padding-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
}

.DisplayField__value {
    white-space: pre-wrap;
    overflow-y: auto;
}

.DisplayField--inline {
    display: flex;

    .DisplayField__label {
        padding-right: 20px;
        padding-bottom: 0;
        min-width: 100px;
    }
}


@media (min-width: 900px) {
    .DisplayField--columnal {
        display: grid;
        grid-template-columns: 280px 1fr;
        grid-gap: 20px;
        
        .DisplayField__label {
            padding-bottom: 0;
        }
    }
}