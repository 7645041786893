@import '../../../variables.scss';

.Pagination {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Pagination__button--primary {
    border: 1px solid $dark-grey;
    padding: 4px 10px;
    margin: 0 5px;
    background-color: rgb(216, 217, 216);
    cursor: pointer;
}

.Pagination__ellipses {
    display: none;
    color: rgb(136, 140, 141);
    align-self: flex-end;
}

.Pagination__button {
    display: none;
    border: 1px solid $dark-grey;
    padding: 4px 10px;
    margin: 0 5px;
    background-color: rgb(216, 217, 216);
    cursor: pointer;
}

.Pagination__button--current {
    border: 1px solid $dark-grey;
    padding: 4px 10px;
    margin: 0 7px;
    background-color: rgb(240, 240, 240);
    font-weight: bold;
    cursor: pointer;
}

@media (min-width: 600px) {
    .Pagination__ellipses {
        display: block;
    }

    .Pagination__button {
        display: block
    }
}