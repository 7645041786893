.TrialsPage {
    padding: 20px 20px 40px;
    font-size: 18px;
}

.TrialsPage__tagline {
    width: 100%;
    text-align: center;
}

.TrialsPage__content {
    padding-top: 40px;
}