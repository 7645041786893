@import '../../../variables.scss';

.GuildOverviewPage {

}

.GuildOverviewPage__headerContent {
    padding: 40px 0 20px;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    font-weight: bold;
}

.GuildOverviewPage__centerBlock {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    // grid-row-start: 2;
    // grid-column: span 2;
}

.GuildOverviewPage__block {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
}

.GuildOverviewPage__label {
    padding-bottom: 10px;
    font-size: 14px;
    color: $gold;
}

.GuildOverviewPage__value {
    overflow-wrap: anywhere;
    font-size: 20px;
}

.GuildOverviewPage__divider {
    padding: 20px 0 30px;
}

.GuildOverviewPage__field {
    padding: 15px 0;
}

.GuildOverviewPage__formButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.GuildOverviewPage__button {
    padding: 10px 10px;
}

.GuildOverviewPage__formError {
    padding-bottom: 15px;
}

.GuildOverviewPage__chatFeed {
    padding-top: 50px;
}

.GuildOverviewPage__search {
    padding: 20px 0;
}

@media (min-width: 600px) {
    .GuildOverviewPage__headerContent {
        grid-template-columns: 1fr 1fr;
    }

    .GuildOverviewPage__label {
        font-size: 18px;
    }

    .GuildOverviewPage__value {
        font-size: 26px;
    }
}

@media (min-width: 900px) {
    .GuildOverviewPage__headerContent {
        padding: 40px 40px 30px;
        display: flex;
        // justify-content: space-between;
        justify-content: space-around;   
    }
}