@import "../../../variables.scss";

.VipPage {
    padding: 0 20px;
    font-size: 18px;
    color: $primary-font-color;
}

.VipPage__tagline {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 30px;
}

.VipPage__logo {
    width: 100px;
}

.VipPage__header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.VipPage__icon {
    width: 60px;
    margin: 0 10px;
}

.VipPage__header {
    font-size: $size-28-38;
    line-height: $size-28-38;
    font-weight: bold;
    text-align: center;
    padding-bottom: 20px;
}

.VipPage__packs {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 20px;
    padding-bottom: 10px;
}

.VipPage__packCard {
    width: 100%;
    transition: $quick-ease;
}

.VipPage__packCard:hover {
    transform: scale(1.01);
}

.VipPage__packsContent {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

.VipPage__table {
    padding-bottom: 30px;
}

.VipPage__buttons {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

.VipPage__footer {
    font-size: 16px;
}

.Vip_Page__header_trial {
    text-align: center;
    color: darkred;
}


@media (min-width: 600px) {
    .VipPage__tagline {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 20px;
    }

    .VipPage__logo {
        width: 135px;
    }

    .VipPage__packs {
        grid-template-columns: repeat(2, auto);
    }
}

@media (min-width: 1200px) {
    .VipPage__logo {
        width: 100px;
    }

    .VipPage__tagline {
        font-size: 20px;
    }
}