.JobsPage {
	padding: 0 20px 20px;
}

.JobsPage__kit {
	margin-bottom: 40px;
}

.JobsPage__subtitle {
	text-align: center;
	margin: 40px;
}

.JobsPage__kitBody {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.JobsPage__kitContent {
	margin-right: 40px;
}

.JobsPage__download {
	img {
		height: 200px;
	}
}

.JobsPage__email {
	display: block;
}

.JobsPage__releases {
	margin-top: 20px;
}

.JobsPage__release {
    padding: 20px;
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    border: 1px solid rgba(34,36,38,.15);
	margin-bottom: 80px;
	border-radius: .28571429rem;
}

@media(min-width: 1200px) {
	.JobsPage__kitBody {
		flex-direction: row-reverse;
		align-items: flex-start;
	}
}
