.CookieNotice {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    background-color: #1b1c1d;
    margin: 0 auto;
}

.CookieNotice__body {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    padding: 10px;
}

.CookieNotice__terms {
    color: white;
    word-break: break-word;
    width: 100%;
    padding: 10px 20px;
}

.CookieNotice__button {
    width: 100%;
    height: min-content;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
}

@media (min-width: 600px) {
    .CookieNotice__body {
        flex-wrap: nowrap;
    }

    .CookieNotice__button {
        width: min-content;
    }
}