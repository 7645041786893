@import '../../../variables.scss';

.MarketingCTA {
    width: 100%;
    padding: 12px;
    background-color: $light-grey;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    box-shadow: $standard-box-shadow;
}

.MarketingCTA__children {
    padding-bottom: 10px;
    font-size: 18px;
    width: 100%;
}

.MarketingCTA__button {

}

@media (min-width: 900px) {

    .MarketingCTA {
        padding: 20px;
        flex-wrap: nowrap;
        flex-direction: row-reverse;
        align-items: center;
    }

    .MarketingCTA__button {
        padding-right: 20px;
        border-right: 2px solid $medium-grey;
    }

    .MarketingCTA__children {
        padding-bottom: 0px;
        padding-left: 20px;
        font-size: 18px;
        line-height: 20px;
        height: min-content;
    }
}

@media (min-width: 1200px) {
    
    .MarketingCTA__children {
        font-size: 22px;
        line-height: 26px;
    }

}