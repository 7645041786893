@import "../../../variables.scss";


.DisplayTableHorizontal {
    display: block;
    overflow-x: auto;
    padding-bottom: 15px;
}

.DisplayTableHorizontal__tableContent {
    position: relative;
}

.DisplayTable__topDivider {
    position: absolute;
    top: -5px;
    width: 100%;
    z-index: 2;
}

.DisplayTable__bottomDivider {
    position: absolute;
    bottom: -5px;
    z-index: 2;
    width: 100%;
}

.DisplayTableHorizontal__label {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 0 10px 10px;
    min-width: 150px;
}

.DisplayTableHorizontal__element {
    padding: 10px;
    font-size: 16px;
    min-width: 150px;
}

.DisplayTableHorizontal__element--gold {
    filter: drop-shadow(5px 5px 5px $medium-dark-grey)
}

.DisplayTableHorizontal__rowHeader {
    font-weight: bold;
}

.DisplayTableHorizontal__subRowHeader {
    padding-left: 30px;
    font-weight: bold;
}

.DisplayTableHorizontal__element--gray {
    background-color: $light-med-grey;
}

.DisplayTableHorizontal__element--lightGray {
    background-color: $light-grey;
}

.DisplayTableHorizontal__element--gold {
    background-image: linear-gradient(to right, rgb(227, 196, 132), rgb(227, 212, 180), rgb(205, 176, 117));
}

.DisplayTableHorizontal__section:nth-child(2n+1) {
    .DisplayTableHorizontal__element--gray {
        background-color: darken($light-med-grey, 10%);
    }

    .DisplayTableHorizontal__element--lightGray {
        background-color: darken($light-grey, 6%);
    }

    .DisplayTableHorizontal__element--gold {
        background-image: linear-gradient(to right, rgb(218, 199, 153), rgb(218, 210, 186), rgb(201, 185, 142));
    }
}

.DisplayTableHorizontal__section:nth-child(2) {
    .DisplayTableHorizontal__row:first-child {
        .DisplayTableHorizontal__element {
            padding-top: 25px;
        }
    }
}

.DisplayTableHorizontal__section:nth-last-child(2) {
    .DisplayTableHorizontal__row:last-child {
        .DisplayTableHorizontal__element {
            padding-bottom: 25px;
        }
    }
}

.DisplayTable__grid--1 {
    display: grid;
    align-items: stretch;
    grid-template-columns: 1fr;
}

.DisplayTable__grid--2 {
    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(2, 1fr);
}

.DisplayTable__grid--3 {
    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(3, 1fr);
}

.DisplayTable__grid--4 {
    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(4, 1fr);
}

.DisplayTable__grid--5 {
    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(5, 1fr);
}

.DisplayTable__grid--6 {
    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(6, 1fr);
}

.DisplayTable__grid--7 {
    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(7, 1fr);
}

@media (min-width: 900px) {
    .DisplayTableHorizontal__labels {
        margin: 0 36px;
    }

    .DisplayTableHorizontal__section {
        margin: 0 36px;
    }
}