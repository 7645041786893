.Footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #08090f;
	padding: 50px 0;
	color: #777;
	width: 100%;
	bottom: 0;
	text-align: center;
	min-height: 425px;
}

.Footer__copywrite {
	color: #30556b;
	font-size: 18px;
}

.Footer__links {
	display: inline-block;
	width: 100%;
	margin: 20px 0;
}

.Footer__link {
	display: inline;
	color: #c3c3c3;
	font-size: 16px;
	cursor: pointer;
}

.Footer__link:hover {
	color: #fff;
}

.Footer__spacer {
	color: #c3c3c3;
	display: inline-block;
	margin: 0 10px;
}

.Footer__social {
	display: flex;
	margin: 20px;
}

.Footer__socialLink {
	margin: 5px;
}

.Footer__logos {
	justify-content: center;
	margin: 10px 0;
	display: flex;
	align-items: center;
	flex-flow: wrap;
}

.Footer__logo {
	margin: 10px;
	min-width: 200px;
}

.Footer__logo--crowfall {
}

.Footer__logo--crowfall img {
	height: 40px;
	color: #d4d4d4;
	filter: opacity(80%);
}

@media(min-width: 500px) {
	.Footer__logos {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
