.ColorSwatch {
	width: 50px;
	height: 50px;
	border: solid gray 0.5px;
}

.ColorSwatch__metallicOverlay {
	width: 100%;
	height: 100%;
}
