@import "../../../variables.scss";

.ECSContentPage {
    color: $primary-font-color;
    font-size: 18px;
    padding: 0 20px;
}

.ECSContentPage__title {
    padding-bottom: 30px;
    font-weight: bold;
    font-size: $size-28-38;
    text-align: center;
}

.ECSContentPage__tagline {
    text-align: center;
    margin-bottom: 30px;
}

.ECSContentPage__body {
    margin-bottom: 20px;
}