@import '../../../variables.scss';

.RegistrationForm {
    width: 100%;
    max-width: 1000px;
}

.RegistrationForm__section {
    padding: 15px 0;
}

.RegistrationForm__h3 {
    font-weight: bold;
    padding-bottom: 12px;
    font-size: 1.28rem;
}

.RegistrationForm__h5 {
    font-weight: bold;
    padding-bottom: 12px;
    font-size: 1.07rem;
}

.RegistrationForm__buttons {
    display: flex;
    justify-content: flex-end;
}