// general color scheme


$light-grey: rgb(244, 244, 244);

$light-grey-opaque-70: rgba(244, 244, 244, .7);

$light-med-grey: rgb(233, 233, 233);

$light-med-grey-opaque-90: rgba(233, 233, 233, .9);

$medium-grey: rgb(222, 222, 222);

$medium-dark-grey: rgb(194, 194, 194);

$dark-grey: rgb(107, 107, 107);

$dark-grey-opaque-80: rgba(23, 40, 51, .8);

$light-black: rgb(84, 84, 84);

$medium-green: rgb(65, 150, 65);

$medium-dark-green: rgb(51, 122, 51);

$light-orange: rgb(255, 235, 204);

$medium-orange: rgb(216, 108, 0);

$dark-orange: rgb(128, 77, 0);

$light-red: rgb(255, 232, 230);

$dark-red: rgb(204, 41, 0);

$dark-red-opaque-80: rgba(204, 41, 0, .8);

$mustard-yellow: rgb(237, 184, 0);

$medium-blue: rgb(73, 152, 161);

$medium-dark-blue: rgb(63, 133, 140);

$dark-blue: rgb(39, 55, 112);

$medium-blue-opaque: rgba(73, 152, 161, .3);

$gold: rgb(193, 148, 94);



// Social Media Color Scheme

$twitter-blue: rgb(29, 161, 242);

$twitter-blue-dark: rgb(26, 142, 210);

$facebook-blue: rgb(24, 119, 242);

$facebook-blue-dark: rgb(21, 102, 210);

$email-blue: rgb(48, 100, 127);

$email-blue-dark: rgb(40, 82, 105);

$instagram-pink: rgb(225,48,108);

$instagram-pink-dark: rgb(196, 41, 92);

$youtube-red: rgb(255, 0, 0);

$youtube-red-dark: rgb(208, 0, 0);

$twitch-purple: rgb(145, 70, 255);

$twitch-purple-dark: rgb(126, 72, 217);

$google-red: rgb(221, 76, 64);

$google-red-dark: rgb(199, 68, 58);

$discord-blurple: rgb(88, 101, 242);

$discord-blurple-dark: rgb(75, 88, 206);



// font colors

$primary-font-color: rgb(24, 27, 33);

$secondary-font-color-hover: rgb(60, 60, 60);

$secondary-font-color: rgb(80, 80, 80);

$tertiary-font-color: rgb(100, 100, 100);

$minor-font-color: rgb(120, 120, 120);

$sub-minor-font-color: rgb(160, 160, 160);

$marketing-blue: rgb(30, 55, 107);

$marketing-light-blue: rgba(80, 105, 157, .75);



// font-size || line-height min-max calculations

$size-14-18: calc(14px + (18 - 14) * ((100vw - 300px) / (2400 - 300)) );

$size-16-20: calc(16px + (20 - 16) * ((100vw - 300px) / (2400 - 300)) );

$size-20-24: calc(20px + (24 - 20) * ((100vw - 300px) / (2400 - 300)) );

$size-22-28: calc(22px + (28 - 22) * ((100vw - 300px) / (2400 - 300)) );

$size-24-32: calc(24px + (32 - 24) * ((100vw - 300px) / (2400 - 300)) );

$size-26-34: calc(26px + (34 - 26) * ((100vw - 300px) / (2400 - 300)) );

$size-28-38: calc(28px + (38 - 28) * ((100vw - 300px) / (2400 - 300)) );

$size-32-42: calc(32px + (42 - 32) * ((100vw - 300px) / (2400 - 300)) );

$size-36-48: calc(36px + (48 - 36) * ((100vw - 300px) / (2400 - 300)) );



// Box-Shadows

$standard-box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.38);

$large-box-shadow: 1px 4px 14px 4px rgba(0, 0, 0,.7);



// Drop-shadows

$h1-drop-shadow: drop-shadow(3px 2px 2px black);

$h3-drop-shadow: drop-shadow(5px 2px 2px black);



// gradients

$vertical-light-to-dark: linear-gradient(rgb(228, 228, 228), rgb(199, 199, 199));



// Transitions

$quick-ease: all .5s ease;



// Text Background Effect (to enhance visibility over images)

@mixin BlurBackgroundEffect($radius) {
    background: rgba(0, 0, 0, .3);
    filter: blur($radius);
    border-radius: $radius;
    width: 100%;
    height: 100%;
}


// Generic modal styles.

@mixin genericModalSetup() {
    background-color: white;
    border-radius: 10px;
    padding: 40px 20px 20px;
    max-width: 600px;
    min-width: 280px;
    font-size: 16px;
}

@mixin genericModalHeader() {
    font-size: 26px;
    line-height: 28px;
    color: $secondary-font-color;
    text-align: center;
    padding-bottom: 25px;
    font-weight: bold;
}
