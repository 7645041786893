.MainMenu {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

.Menu__item {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	position: relative;
	padding: 20px;
	width: 100%;
	display: flex;
	user-select: none;
}

.Menu__item--orange .Menu__icon {
	color: orange;
}

.Menu__link {
    white-space: nowrap;
    padding: 0 5px;
}

.Menu__label {
	color: hsla(0,0%,100%,.9);
	font-size: 2em;
	display: flex;
	white-space: nowrap;
	align-items: center;
}

.Menu__carrot {
	width: 28px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	display: flex;
	min-width: 8px;
	transform: rotate(-90deg);

	i {
		margin: 0;
	}
}

.Menu__icon {
	margin-right: 7px;
}

.Menu__subSections {
	display: none;
}

.Menu__divider {
	border-top: 1px solid rgba(34,36,38,.1);
	height: 0;
	margin: .5em 0;
}

.Menu__subLabel {
	color: hsla(0,0%,100%,.9);
	text-transform: uppercase;
	margin: 20px;
	font-weight: 700;
}

.Menu__linkLabel {
	padding: 15px 20px;
	color: hsla(0,0%,100%,.9);
}

// .Menu__linkLabel-white {
// 	color: rgba(255, 255, 255, 0.9);
// }

.Menu__item--selected {
	.Menu__label {
		margin-bottom: 10px;
	}

	.Menu__subSections {
		display: inline-block;
	}

	.Menu__carrot {
		transform: rotate(0deg);
	}
}

@media(min-width: 900px) {
	.MainMenu {
		flex-direction: row;
		.Menu__carrot {
			transform: rotate(-90deg);
		}
	}

	.Menu__carrot {
		display: none;
	}

	.Menu__label {
		font-size: .9rem;
		user-select: none;
	}

	.Menu__subLabel {
		color: black;
	}

	.Menu__linkLabel {
		padding: 10px 20px;
		color: black;
	}

	.Menu__subSections {
		position: absolute;
		top: 100%;
		left: 0;
		background-color: rgba(230,230,230,0.95);
	}

	.Menu__item {
		flex-direction: row;
		height: 100%;
		align-items: center;
		padding: 20px 12px;
	}

	.Menu__item:hover {
		background-color: hsla(0,0%,100%,.08);
		.Menu__subSections {
			display: inline-block;
		}

		.Menu__carrot {
			transform: rotate(0deg);
		}
	}

	.Menu__link:hover {
		background-color: rgba(0,0,0,.05);
	}

	.Menu__item--selected {
		.Menu__label {
			margin-bottom: 0;
		}

    	.Menu__subSections {
    		display: none;
    	}
	}

	.Menu__item--mobile {
		display: none;
	}

}

@media (min-width: 1200px) {

	.Menu__label {
		font-size: 1.07rem;
	}

	.Menu__item {
		padding: 20px 15px;
	}
}

@media(min-width: 1300px) {
	.Menu__item {
		padding: 20px;
	}
}
