.AccountAdditionalDownloadsPage {
}

.AccountAdditionalDownloadsPage p {
    font-size: 18px;
}

.AccountAdditionalDownloadsPage__razerButtons {
    display: flex; 
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.AccountAdditionalDownloadsPage__razerBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.AccountAdditionalDownloadsPage__razerImage {
    height: 450px;
    margin-bottom: 20px;
}