@import '../../../variables.scss';

.SocialLink {
	color: white;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 3px;
	font-size: 14px;
	transition: $quick-ease;
}

.SocialLink--twitter {
	background-color: $twitter-blue;
}

.SocialLink--twitter:hover {
	background-color: $twitter-blue-dark;
}

.SocialLink--facebook {
	background-color: $facebook-blue;
}

.SocialLink--facebook:hover {
	background-color: $facebook-blue-dark;
}

.SocialLink--twitch {
	background-color: $twitch-purple;
}

.SocialLink--twitch:hover {
	background-color: $twitch-purple-dark;
}

.SocialLink--google {
	background-color: $google-red;
}

.SocialLink--google:hover {
	background-color: $google-red-dark;
}

.SocialLink--instagram {
	background-color: $instagram-pink;
}

.SocialLink--instagram:hover {
	background-color: $instagram-pink-dark;
}

.SocialLink--youtube {
	background-color: $youtube-red;
}

.SocialLink--youtube:hover {
	background-color: $youtube-red-dark;
}

.SocialLink--discord {
	background-color: $discord-blurple;
}

.SocialLink--discord:hover {
	background-color: $discord-blurple-dark;
}
