@import '../../../variables.scss';

.Capsule {
    border: 1px solid $secondary-font-color;
    border-radius: 15px;
    padding: 0 15px;
    height: 30px;
    display: flex; 
    justify-content: center;
    align-items: center;
    background-color: $minor-font-color;
    color: white;
    font-weight: bold;
    font-size: 16px;
    cursor: default;
}

.Capsule--orange {
    background-color: $medium-orange;
}

.Capsule--red {
    background-color: $dark-red;
}

.Capsule--yellow {
    background-color: $mustard-yellow;
}

.Capsule--green {
    background-color: $medium-green;
}

.Capsule--blue {
    background-color: $dark-blue;
}