@import '../../../variables.scss';

.PublicGuildOverview {
    width: 100%;
    max-width: 1100px;
    color: $primary-font-color;
    font-size: $size-22-28;
    line-height: $size-26-34;
}

.PublicGuildOverview__header {
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.PublicGuildOverview__guildTitles {
    text-align: center;
}

.PublicGuildOverview__displayName {
    font-size: $size-32-42;
    line-height: $size-32-42;
    width: 100%;
    font-weight: bold;
    padding: 30px 0 20px;
}

.PublicGuildOverview__name {
    font-size: $size-28-38;
    line-height: $size-32-42;
    width: 100%;
    color: $secondary-font-color;
    padding-bottom: 30px;
}

.PublicGuildOverview__motto {
    width: 100%;
}

.PublicGuildOverview__descriptionBox {
    border: 1px solid $gold;
    font-size: $size-16-20;
    border-radius: 14px;
    background-color: $light-grey;
    margin-top: 40px;
    padding: 20px;
}

.PublicGuildOverview__description {
    padding: 10px;
    white-space: pre-wrap;
    max-height: 250px;
    overflow-y: auto;
}

.PublicGuildOverview__listSpacer {
    padding: 0 8px;
}

.PublicGuildOverview__joinButton {
    padding-top: 30px;
    display: flex;
    justify-content: center;
}

.PublicGuildOverview__content {
    font-size: $size-20-24;
    line-height: $size-22-28;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid $gold;
}

.PublicGuildOverview__leftContent {
    padding: 0 20px 10px;
}

.PublicGuildOverview__guildInfo {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
}

.PublicGuildOverview__rightContent {
    border-top: 1px solid $gold;
    padding: 20px 20px 0;
}

.PublicGuildOverview__field {
    display: flex;
    padding-bottom: 10px;
}

.PublicGuildOverview__label {
    font-weight: bold;
    padding-right: 10px;
    min-width: 80px;
}

.PublicGuildOverview__value {
    display: flex;
    flex-wrap: wrap;
}

.PublicGuildOverview__subValue {
    display: flex;
}

.PublicGuildOverview__tableLabel {
    font-size: 18px;
}

.PublicGuildOverview__pagination {
    padding-top: 20px;
}

.PublicGuildOverview__swalTitle {
    padding: 13px 16px;
    font-weight: bold;
    font-size: 27px;
    line-height: normal;
    color: rgba(0,0,0,.65);
}

@media (min-width: 900px) {

    .PublicGuildOverview__header {
        flex-wrap: nowrap;
    }

    .PublicGuildOverview__guildTitles {
        padding-left: 30px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        text-align: start;
    }

    .PublicGuildOverview__displayName {
        width: 100%;
        padding: 0 0 20px;
    }

    .PublicGuildOverview__name {
        width: 100%;
    }

    .PublicGuildOverview__motto {
        width: 100%;
    }

    .PublicGuildOverview__description {
        max-height: 550px;
    }

    .PublicGuildOverview__content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .PublicGuildOverview__leftContent {
        border-right: 1px solid $gold;
        padding: 10px 20px 0 0;
        border-bottom: none;
    }

    .PublicGuildOverview__rightContent {
        border-top: none;
        padding: 10px 0 0;
    }

    .PublicGuildOverview__field {
        padding: 0 20px 15px;
    }

    .PublicGuildOverview__membersTable {
        padding: 0 20px 20px;
    }

}

.PublicGuildOverview__cannot_create_display {
    margin-top: 30px;
}