@import "../../../variables.scss";

.ProductGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ProductGrid__emptyText {
    font-size: $size-16-20;
    line-height: $size-20-24;
    padding: 10px 0 20px;
}