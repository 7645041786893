.PromoNewsSection {
    background-color: #08090f;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='32' viewBox='0 0 16 32'%3E%3Cg fill='%231e223a' fill-opacity='0.08'%3E%3Cpath fill-rule='evenodd' d='M0 24h4v2H0v-2zm0 4h6v2H0v-2zm0-8h2v2H0v-2zM0 0h4v2H0V0zm0 4h2v2H0V4zm16 20h-6v2h6v-2zm0 4H8v2h8v-2zm0-8h-4v2h4v-2zm0-20h-6v2h6V0zm0 4h-4v2h4V4zm-2 12h2v2h-2v-2zm0-8h2v2h-2V8zM2 8h10v2H2V8zm0 8h10v2H2v-2zm-2-4h14v2H0v-2zm4-8h6v2H4V4zm0 16h6v2H4v-2zM6 0h2v2H6V0zm0 24h2v2H6v-2z'/%3E%3C/g%3E%3C/svg%3E");
}

.PromoNewsSection__promos {
    display: grid;
    padding: 10px 0;
    grid-gap: 15px;
    grid-template-columns: repeat(1, 1fr);
}

.PromoNewsSection__mediaColumn {
    display: none;
}

.PromoNewsSection__mediaRow {
    display: flex;
    flex-wrap: wrap;
	justify-content: center;
    padding: 20px 0;
    width: 100%;
}

.PromoNewsSection__mediaTitle {
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
}

.PromoNewsSection__mediaLabel {
    color: white;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    padding-Bottom: 15px;
}

.PromoNewsSection--noPromos {
    .PromoNewsSection__promos {
        display: none;
    }

    .PromoNewsSection__mediaRow {
        padding-top: 40px;
    }
}

@media (min-width: 1200px) {    
    .PromoNewsSection {
        position: relative;
        top: -7vw;
        margin-bottom: -7vw;
        background: none;
    }

    .PromoNewsSection__promos {
        padding: 30px 30px 0 30px;
        grid-gap: 30px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    .PromoNewsSection__promos--2 {
        .PromoNewsSection__promo {
            grid-column: span 2;
        }
    }

    .PromoNewsSection__promos--3 {
        .PromoNewsSection__promo:first-child {
            grid-column: span 2;
        }
    }

    .PromoNewsSection__mediaTitle {
        padding: 10px 0;
    }

    .PromoNewsSection__media {
        padding-top: 15px;
    }


    .PromoNewsSection--noPromos {
        .PromoNewsSection__promos {
            display: none;
        }

        .PromoNewsSection__mediaColumn {
            display: none;
        }

        .PromoNewsSection__mediaRow {
            display: flex;
        }

        .PromoNewsSection__mediaTitle {
            padding: 0 10px;
        }
    }

    .PromoNewsSection--singlePromo {
        display: block;

        .PromoNewsSection__promos {
            display: block;
            width: 100%;
            padding-bottom: 10px;
        }

        .PromoNewsSection__mediaColumn {
            display: none;
        }

        .PromoNewsSection__mediaRow {
            display: flex;
            flex-wrap: wrap;
	        justify-content: center;
            padding: 20px 0;
            width: 100%;
        }
    }
}

@media (min-width: 1500px) {

    .PromoNewsSection {
        display: flex;
    }

    .PromoNewsSection__promos {
        width: 70%;
        padding: 30px;
    }

    .PromoNewsSection__mediaColumn {
        display: block;
        width: 30%;
        padding: 30px 30px 30px 0;
    }

    .PromoNewsSection__mediaRow {
        display: none;
    }

    .PromoNewsSection--singlePromo {
        display: inline-block;
        width: 100%;
    }
}

@media (min-width: 2000px) {
    .PromoNewsSection {
        top: -150px;
        margin-bottom: -150px;
    }

    .PromoNewsSection__promos {
        width: 75%;
    }

    .PromoNewsSection__mediaColumn {
        width: 25%;
    }
}