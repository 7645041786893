.MediaList {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	cursor: pointer;
}

.MediaList__media {
	position: relative;
}

.MediaList__thumb {
	margin: 0 10px 20px;
}

.MediaList__load {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 10px 20px;
}

.MediaList__loadInner {
	background-color: rgba(48,85,107,0.9);
	width: 100%;
	height: 100%;
    font-size: 24px;
    font-family: "Open Sans Condensed",Arial,Helvetica,sans-serif;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(min-width: 479px) {
	.MediaList {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media(min-width: 769px) {
	.MediaList {
		grid-template-columns: repeat(3, 1fr);
	}
}
