.AssetPicker {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
}

.AssetPicker__title {
	width: 100%;
	font-size: 18px;
    color: #15171c;
    font-weight: 700;
    padding-left: 20px;
    letter-spacing: 0.1em;
    margin-bottom: 15px;
    text-align: center;
}

.AssetPicker__assets {
	
}

.AssetPicker__asset {
	position: relative;
	margin: 7px;
	cursor: pointer;
}

.AssetPicker__asset:after {
	content: '';
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: center / 100% 100% no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHQAAACDAQMAAABmw63FAAAABlBMVEUAAAAwVWucoE2eAAAAAXRSTlMAQObYZgAAACxJREFUSMdj+P+BAQ6AHIL8BwzIoGDA+aNgFIyCUTAKRsFQAA8GWf1JYv0PAP67LtUYCvFJAAAAAElFTkSuQmCC);
}

.AssetPicker__asset--selected:after {
	display: block;
	top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
}
