@import '../../../variables.scss';

.Stepper {

}

.Stepper__step {
    display: grid;
    grid-template-columns: 30px auto;
    grid-gap: 20px;
    position: relative;
    padding-bottom: 20px;
}

.Stepper__step:last-child {
    padding-bottom: 0;
}

.Stepper__connectorLine {
    background-color: $light-med-grey;
    width: 2px;
    position: absolute;
    top: 0;
    left: 13px;
    height: 100%;
    z-index: -1;
}

.Stepper__stepCount {
    background-color: $dark-grey;
    box-shadow: $standard-box-shadow;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 16px;
}

.Stepper__stepBody {
    max-width: 100%;
    overflow: hidden;
}

.Stepper__stepLabel {
    font-size: 22px;
    font-weight: bold;
    color: $primary-font-color;
    padding: 5px 0 20px;
}

.Stepper__stepContent {

}

.Stepper__step--active {
    .Stepper__stepCount {
        background-color: $medium-blue;
    }
}

.Stepper__step--complete {
    .Stepper__stepCount {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        font-size: 10px;
        margin-left: 5px;
    }

    .Stepper__stepLabel {
        padding: 0;
        font-size: 16px;
    }
}