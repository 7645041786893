@import '../../../variables.scss';

.FormMessage {
    border: 1px solid $medium-grey;
    border-radius: 2px;
    background-color: $light-med-grey;
    padding: 8px 12px;
    color: $secondary-font-color;
}

.FormMessage__title {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 5px;
}

.FormMessage__content {
    font-size: 14px;
}

.FormMessage--warning {
    border: 1px solid $dark-orange;
    background-color: $light-orange;
    color: $dark-orange;
}

.FormMessage--error {
    border: 1px solid $dark-red;
    background-color: $light-red;
    color: $dark-red;
}

@media (min-width: 900px) {
    .FormMessage--columnal {
        margin-left: 220px;
    }
}