.CrowfallPartnerRibbon {
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
}

.CrowfallPartnerRibbon__partnerLogo {
    height: 30px;
    margin: 10px 0;
}

.CrowfallPartnerRibbon__crowfallLogo {
    height: 30px;
    margin: 10px 0;
}

.CrowfallPartnerRibbon__divider {
    height: 30px;
    margin: 0 10px;
}

.CrowfallPartnerLogo--largePartnerLogo {
    .CrowfallPartnerRibbon__partnerLogo {
        height: 50px;
    }
}

@media (min-width: 900px) {
    .CrowfallPartnerRibbon {
        padding: 76px 10px 20px;
    }
}

@media (min-width: 1200px) {
    .CrowfallPartnerRibbon {
        padding-top: 10px;
		background-color: rgba(0, 0, 0, 0.95);
    }

    .CrowfallPartnerRibbon__partnerLogo {
        height: 40px;
    }

    .CrowfallPartnerRibbon__crowfallLogo {
        height: 40px;
    }

    .CrowfallPartnerRibbon__divider {
        height: 40px;
    }

    .CrowfallPartnerLogo--largePartnerLogo {
        .CrowfallPartnerRibbon__partnerLogo {
            height: 65px;
        }
    }
}