.PartnersPage__section {
	margin-bottom: 100px;
}

.PartnersPage__title {
	margin: 20px;
	text-align: center;
}

.PartnersPage__logos {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-around;
	margin-bottom: 20px;
}

.PartnersPage__logo {
    max-width: 100%;
    padding: 20px;
}

@media(min-width: 1200px) {
	.PartnersPage__logo {
		margin: 40px;
        max-height: 150px;
	}
}

