@import '../../../variables.scss';

.Searchbar {
	display: flex;
	align-items: center;
	height: 100%;
	border: 1px solid black;
	padding: 8px 10px;
	background-color: $light-grey;
}

.Searchbar__searchIcon {
	color: $minor-font-color;
	font-size: 20px;
}

.Searchbar__input {
	position: relative;
	width: 100%;
	padding-left: 10px;
	border: none;
	background: none;
}

.Searchbar__input::-webkit-input-placeholder {
	font-style: italic;
	color: $minor-font-color;
}

.Searchbar__input:focus {
	outline: none;
}

.Searchbar__clearIcon {
	cursor: pointer;
	color: $minor-font-color;
	font-size: 20px;

}