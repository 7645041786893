.PromotionsPage {
    padding: 0 20px 20px;
}

.PromotionsPage__featuredSection {
    padding: 0 0 40px;
    display: grid;
    grid-template-columns: repeat((1, 1fr));
    grid-gap: 30px;
}

.PromotionsPage__archivedSection {
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat((1, 1fr));
    grid-gap: 20px;
}

.PromotionsPage__pagination {
    padding-top: 10px;
}