.Registration {
    display: flex;
    justify-content: center;
    padding: 50px 20px 0;
}

.Registration__content {
    width: 100%;
    max-width: 900px;
}

@media(min-width: 1200px) {
    .Registration {
        padding-top: 0;
    }
}