@import '../../../variables.scss';

.BannerHeader {
    display: flex;
	justify-content: center;
	align-items: flex-end;
	width: 100%;
	height: 325px;
	padding: 106px 10px 20px;
	background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-weight: bold;
    position: relative;
}

.BannerHeader__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    z-index: 3;
    padding: 0 10px;
    text-align: center;
}

.BannerHeader__title {
    width: 100%;
    color: white;
    font-size: calc(2em + 1vw);
    line-height: 1.1em;
    overflow-wrap: anywhere;
    display: flex;
    justify-content: center;
    letter-spacing: .4rem;
    text-shadow: rgba(0,0,0,0.2) 1px 2px 6px;
    filter: $h3-drop-shadow;
}

.BannerHeader__titleAsset {
    width: 260px;
    height: 58px;
    filter: $h3-drop-shadow;
}

.BannerHeader__subtitle {
    font-size: calc(1.4em + 1vw);
    line-height: calc(1.3em + 1vw);
    color: #fff;
    filter: $h3-drop-shadow;
    margin-top: 10px;
}

.BannerHeader__accent {
    position: absolute;
    bottom: 0;
    height: 50px;
    overflow: hidden;
    margin-bottom: 5px;
    opacity: .3;
}

.BannerHeader--isMarketing {
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;

    .BannerHeader__content {
        width: 100%;
        text-align: left;
    }

    .BannerHeader__title {
        color: #d48d31;
        justify-content: left;
        font-size: calc(1.8em + 1vw)
    }

    .BannerHeader__subtitle {
        width: 100%;
        font-size: calc(1em + 1vw);
        line-height: calc(.9em + 1vw);
        margin-top: 30px;
    }
}

.BannerHeader--short {
    height: 300px;
}

.BannerHeader__ribbon {
    width: 100%;
}

@media(min-width: 600px) {
    .BannerHeader {
        padding-bottom: 30px;
    }

    .BannerHeader__accent {
        height: 70px;
    }

    .BannerHeader__titleAsset {
        width: 340px;
        height: 76px;
    }

    .BannerHeader--short {
        height: 270px;
    }
}

@media(min-width: 900px) {
    .BannerHeader {
        height: 530px;
        padding-bottom: 40px;
    }

    .BannerHeader__title {
        font-size: calc(2.8em + 1vw);
        letter-spacing: .5rem;
    }

    .BannerHeader__accent {
        height: 80px;
    }

    .BannerHeader__subtitle {
        margin-top: 20px;
    }

    .BannerHeader__titleAsset {
        width: 450px;
        height: 101px;
    }

    .BannerHeader--isMarketing {
        .BannerHeader__title {
            font-size: calc(2.8em + 1vw);
        }

        .BannerHeader__subtitle {
            margin-top: 50px;
        }
    }

    .BannerHeader--short {
        height: 250px;
    }
}

@media(min-width: 1200px) {
    .BannerHeader__accent {
        height: 95px;
    }

    .BannerHeader__title {
        font-size: 4.2em;
    }

    .BannerHeader__subtitle {
        font-size:  2.8em;
    }

    .BannerHeader__titleAsset {
        width: 600px;
        height: 134px;
    }

    .BannerHeader--isMarketing {
        .BannerHeader__title {
            font-size: 4em;
        }

        .BannerHeader__subtitle {
            font-size:  2.3em;
        }
    }
}