.announcement-banner {
  width: 100%;
  margin-top: 66px;
  padding: 35px 20px;
  text-align: center;
  color: #fff;
  background-color: #5c5c5c;
  @media (max-width: 900px) {
    padding: 20px;
  }
  &.announcement-banner-size__small {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    h2 {
      margin-bottom: 0;
      padding: 0 20px;
      font-size: calc(0.8em + 1vw);
    }
    .Button {
      padding: 10px 20px;
    }
  }
  & + .Page {
    margin-top: 0;
  }
  h2 {
    margin-bottom: 20px;
  }
  .Button {
    color: #000;
    background-color: #ce862e;
  }
}
