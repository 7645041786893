.CheckoutPromoCodeForm {
    padding-bottom: 50px;
}

.CheckoutPromoCodeForm__header {
    font-weight: bold;
    padding-bottom: 20px;
    font-size: 1.8rem;
    line-height: 2rem;
}

.CheckoutPromoCodeForm__field {
    padding-bottom: 20px;
}