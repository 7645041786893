.DownloadPackPage {
    font-size: 18px;
}

.DownloadPackPage__header {
    font-weight: bold;
    font-size: 20px;
}

.DownloadPackPage__instructions {
    padding: 5px 0 10px 30px;
}

.DownloadPackPage__line {
    margin-bottom: 10px;
}