@import "../../../variables.scss";

.FieldDropdown {

}

.FieldDropdown__label {
    padding-bottom: 8px;
    font-size: 16px;
    white-space: nowrap;
}

.FieldDropdown__content {
    width: 100%;
}

.FieldDropdown__pills {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 5px;
}

.FieldDropdown__pill {
    padding-right: 5px;
    padding-bottom: 7px;
}

.FieldDropdown__box {
    position: relative;
	display: flex;
	flex-direction: column;
	border-radius: 2px;
	width: 100%;
	cursor: pointer;
	border: 1px solid gray;
	background: $light-grey;
}

.FieldDropdown__innerLabel {
    display: flex;
    justify-content: space-between;
	font-size: 14px;
	padding: 10px 0 10px 20px;
	padding: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.FieldDropdown__value {

}

.FieldDropdown__arrow {
    display: inline-block;
	margin-left: 15px;
	transform: rotate(-90deg);
	color: $minor-font-color;
}

.FieldDropdown__options {
    display: none;
	flex-direction: column;
	overflow-y: scroll;
	max-height: 250px;
}

.FieldDropdown__options::-webkit-scrollbar {
    width: 1em;
}

.FieldDropdown__options::-webkit-scrollbar-track {
	background: none;
}

.FieldDropdown__option {
    padding: 5px 10px 5px 10px;
}

.FieldDropdown__option:hover {
    background-color: $medium-grey;
}

.FieldDropdown__option--selected {
    background-color: $medium-grey;
}

.FieldDropdown__optionLabel {
    text-align: left;
}

.FieldDropdown--expanded {
    height: min-content;

    .FieldDropdown__arrow {
        transform: rotate(0deg);
    }

    .FieldDropdown__options {
        display: flex;
    }
}

.FieldDropdown--above {
    .FieldDropdown__options {
        position: absolute;
        margin-top: 45px;
        width: 100%;
        z-index: 10;
        background-color: $light-grey;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }
}

.FieldDropdown--inline {
    display: flex;
    align-items: center;

    .FieldDropdown__label {
        padding-right: 10px;
        padding-bottom: 0;
    }
}

.FieldDropdown--disabledSingleSelect {
    .FieldDropdown__box {
        cursor: default;
    }

    .FieldDropdown__value {
        color: $minor-font-color;
    }
}

.FieldDropdown--disabledMultiSelect {
    .FieldDropdown__pills {
        padding-bottom: 0;
    }

    .FieldDropdown__box {
        display: none;
    }
}

@media (min-width: 600px) {
    .FieldDropdown--inline .FieldDropdown__box {
        width: 350px;
    }
}

@media (min-width: 900px) {
    .FieldDropdown--columnal {
        display: flex;
        align-items: center;

        .FieldDropdown__label {
            width: 220px;
            padding: 0 20px 0 0;
        }

        .FieldDropdown__content {
            width: auto;
            flex-grow: 1;
        }
    }
}