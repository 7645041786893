@import "../../../../variables.scss";

.ResetPassword {
    background-color: white;
    border-radius: 10px;
    padding: 40px 30px 30px;
    max-width: 600px;
    font-size: 16px;
}

.ResetPassword__header {
    font-size: 26px;
    line-height: 28px;
    color: $secondary-font-color;
    text-align: center;
    padding-bottom: 25px;
    font-weight: bold;
}

.ResetPassword__buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-top: 20px;
}

.ResetPassword__button {
    padding: 10px 0 0 10px;
}