@import "../../../../variables.scss";

.ProductModal {
    background-color: white;
    position: relative;
    min-width: 260px;
    width: 80vw;
}

.ProductModal__capsules {
    position: absolute;
    top: -20px;
    left: 5px;
    display: flex;
}

.ProductModal__capsule {
    padding: 5px;
}

.ProductModal__img {
    width: 100%;
    height: 54vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.ProductModal__body {
    padding: 20px;
}

.ProductModal__name {
    color: $secondary-font-color;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 10px;
    text-align: center;
}

.ProductModal__price {
    font-size: 16px;
    color: $secondary-font-color;
    padding-bottom: 10px;
}

.ProductModal__basePrice {
    text-decoration: line-through;
    color: darkred;
    padding-right: 10px;
}

.ProductModal__scroll {
    overflow-y: auto;
    max-height: 30vh;
}

.ProductModal__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ProductModal__button {
    padding-left: 10px;
    padding-top: 15px;
}


@media(min-width: 600px) {
    .ProductModal {
        width: 480px;
    }

    .ProductModal__img {
        height: 320px;
    }

    .ProductModal__buttons {
        justify-content: flex-end;
    }
}


@media(min-width: 1200px) {
    .ProductModal {
        display: flex;
        height: 400px;
        width: auto;
    }

    .ProductModal__img {
        width: 600px;
        height: 100%;
    }

    .ProductModal__body {
        height: 100%;
        width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px 20px 20px;
    }

    .ProductModal__itemContent {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        height: 312px;
    }

    .ProductModal__name {
        font-size: 24px;
    }

    .ProductModal__price {
        padding-bottom: 15px;
    }

    .ProductModal__scroll {
        max-height: auto;
        height: auto;
    }
}