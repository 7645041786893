@import '../../variables.scss';

.SearchGuildsPage {
    padding: 30px 20px 40px;
}

.SearchGuildsPage__searchbar {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px
}

.SearchGuildsPage__searchInput {
    width: 100%;
    height: min-content;
}

.SearchGuildsPage__searchbarButtons {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0 10px;
}

.SearchGuildsPage__ctaButton {
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.SearchGuildsPage__advancedSearch {
    border-top: 1px solid $dark-grey;
    border-bottom: 1px solid $dark-grey;
    padding: 15px 0;
    margin-bottom: 20px;
}

.SearchGuildsPage__field {
    padding-right: 20px;
    width: 100%;
    padding: 10px;
}

.SearchGuildsPage__button {
    padding: 0 10px;
}

@media (min-width: 600px) {
    .SearchGuildsPage__searchbar {
        flex-wrap: nowrap;
        align-items: center;
    }

    .SearchGuildsPage__searchbarButtons {
        width: min-content;
        padding: 0 0 0 10px;
    }

    .SearchGuildsPage__advancedSearch {
        border-top: none;
        padding: 0;
    }

    .SearchGuildsPage__field {
        width: auto;
    }

    .SearchGuildsPage__searchSection {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 10px 20px;
        border-top: 1px solid $dark-grey;
    }

    .SearchGuildsPage__advancedButtons {
        border-top: 1px solid $dark-grey;
    }

    .SearchGuildsPage__advancedButtons {
        justify-content: flex-end;
    }
}

.SearchGuildsPage__cannot_create_display {
    margin-bottom: 20px;
}