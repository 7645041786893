.ArticlePage {
	font-size: 18px;
}

.ArticlePage img {
	max-width: 100%;
}

.ArticlePage__socialButtons {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding-right: 20px;
}
