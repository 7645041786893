@import "../../../variables.scss";

.TitleBlur {
    width: 100%;
    display: flex;
    justify-content: center;
}

.TitleBlur__title {
    padding: 10px 30px;
    position: relative;
    color: $secondary-font-color;
    text-align: center;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: .2rem;
    font-weight: 900;
}

.TitleBlur__blur {
    background: rgba(0, 0, 0, .1);
    filter: blur(15px);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.TitleBlur--h3 .TitleBlur__title {
    font-size: 18px;
    line-height: 20px;
}

@media (min-width: 900px) {
    .TitleBlur__label {
        font-size: 28px;
        letter-spacing: .3rem;
        line-height: 30px;
    }

    .TitleBlur--h3 .TitleBlur__title {
        font-size: 20px;
        line-height: 22px;
    }
}