@import '../../../variables.scss';

.ContentCard {
    background: $light-grey;
    display: flex;
    flex-flow: column;
    color: #777;
    font-size: 16px;
    box-shadow: $standard-box-shadow;
    height: 100%;
    position: relative;
}

.ContentCard__capsules {
    position: absolute;
    top: -15px;
    left: 20px;
    display: flex;
    align-items: center;
}

.ContentCard__capsule {
    padding-right: 10px;
}

.ContentCard__imgBody {
    overflow: hidden;
}

.ContentCard__img {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
}

.ContentCard__imgLink {
    width: 100%;
    height: 100%;
}

.ContentCard__footer {
    width: 100%;
    flex-grow: 1;
    color: #777;
    padding: 14px 20px;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
}

.ContentCard__footerContent {
    width: 100%;
}

.ContentCard__title {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 4px;
    color: $marketing-blue;
    line-height: 1.25;
    word-break: break-word;
}

.ContentCard__subtitle {
    font-size: 18px;
    color: $marketing-light-blue;
    margin-top: 5px;
}

.ContentCard__tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.ContentCard__tag {
    padding-right: 10px;
}

.ContentCard__tag:last-child {
    padding: 0;
}

.ContentCard__description {
    padding-top: 20px;
    font-size: 18px;
    color: #777;
}

.ContentCard__additionalContent {
    padding-top: 10px;
}

.ContentCard--cardIsALink {
    cursor: pointer;

    .ContentCard__img {
        transition: $quick-ease;
    }
}

.ContentCard--cardIsALink:hover {
    .ContentCard__img {
        transform: scale(1.02);
    }
}

.ContentCard--imgIsALink {
    .ContentCard__img {
        cursor: pointer;
        transition: $quick-ease;
    }
}

.ContentCard--imgIsALink {
    .ContentCard__img:hover {
        transform: scale(1.02);
    }
}

@media (min-width: 900px) {
    .ContentCard--horizontal {
        flex-flow: row;
    
        .ContentCard__imgBody {
            width: 40%;
        }

        .ContentCard__footer {
            width: 60%;
            padding: 20px;
            min-height: 300px;
        }
    }
}
