.Page {
	min-height: calc(100vh - 425px);
	position: relative;
	width: 100%;
}

.Page__desktopRibbon {
	display: none;
}

.Page__mobileRibbon {
	padding: 40px 20px 30px;
}

.Page__body {
	position: relative;
	padding-bottom: 100px;
}

.Page__RadialImgHeader {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 280px;
	background-position: center;
	background-repeat: no-repeat;
	opacity: .05;

}

.Page__RadialImgFooter {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 280px;
	background-position: center;
	background-repeat: no-repeat;
	transform: rotate(180deg);
	bottom: 0;
	opacity: .05;
}


.Page__stonebornFooter {
	position: absolute;
	z-index: 2;
	width: 100%;
	height: 150px;
	background-position: center;
	background-repeat: no-repeat;
	bottom: 0;
}

.Page__title {
    text-align: center;
    padding: 40px;
    line-height: 1.1;
    color: #15171c;
	font-weight: 400;
	position: relative;
	z-index: 3;
}

.Page__content {
	padding: 40px 0;
	position: relative;
	z-index: 3;
	max-width: 1400px;
	margin: 0 auto;
}

.Page__contentHeader {
	font-size: 18px;
	padding: 10px;
	margin-bottom: 40px;
}

.Page--mobileBannerHidden {
	.Page__header {
		display: none;
	}
}

.Page__footer {
	width: 100%;
}


@media(min-width: 900px) {
	.Page__desktopRibbon {
		display: block;
	}

	.Page__mobileRibbon {
		display: none;
	}

	.Page--mobileBannerHidden {
		.Page__header {
			display: block;
		}
	}
	
}

@media(min-width: 1200px) {
	.Page {
		margin-top: 66px;
	}

	.Page__body {
		padding-bottom: 120px;
	}

	.Page__content {
		padding-top: 60px;
	}

	.Page--removeTopMargin {
		margin-top: 0;
	}
}
