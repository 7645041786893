.MainNav {
	background-color: black;
	z-index: 2000;
	display: flex;
	justify-content: center;
	max-width: 2400px;
	height: 100%;
	position: fixed;
}

.MainNav__trial {
    color: Yellow;
    white-space: nowrap;
    display: flex;
    padding-right: 20px;
    font-size: 16px;
    font-weight: 900;
    align-items: center;

     a {
        color: Yellow;
     }
}


.MainNav__link {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	position: relative;
	padding: 20px;
	display: flex;
	color: hsla(0,0%,100%,.9);
	font-size: 2em;
	white-space: nowrap;
	align-items: center;
}

.MainNav__content {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	width: 270px;
	margin: 0;
	margin-left: -270px;
	overflow: auto;
	padding-bottom: 30px;
}

.MainNav__logo {
	width: min-content;
	margin: 20px;
	align-items: center;

	img {
		height: 30px;
	}
}

.MainNav__buttons {
	display: flex;
	flex-direction: column;
	padding: 20px;
}

.MainNav__button {
	margin: 10px 0;
	width: 100%;
	white-space: nowrap;
	min-height: 45px;
}

.MainNav__buttonLabel {
	font-size: 18px;
}

.MainNav__guildNotification {
	display: none;
}

.MainNav__mobileOverlay {
	width: 100%;
	height: 66px;
	position: fixed;
	padding-top: 10px;
	left: 0;
	display: flex;
}

.MainNav__overlayButton {
	height: 50px;
	width: 50px;
	background-color: black;
	border-radius: 10%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	margin-left: 10px;
	padding: 13px 10px;
	z-index: 10;
	cursor: pointer;

	div {
		width: 80%;
		height: 2px;
		border-radius: 10%;
		background-color: rgba(255, 255, 255, 0.9);
	}
}

.MainNav__overlayLogo {
	position: absolute;
	right: 0;
	margin-right: 10px;
	cursor: pointer;

	img {
		height: 30px;
		filter: invert(100%);
	}
}

.MainNav--active {

	.MainNav__content {
		margin-left: 0;
	}

	.MainNav__mobileOverlay {
		left: 270px;
	}
}

@media(min-width: 900px) {
	.MainNav {
		width: 100%;
		height: 66px;
		background-color: rgba(0, 0, 0, 0.95);
		padding: 0 12px;
	}

	.MainNav__content {
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		max-width: 1600px;
		margin-left: 0;
		overflow: visible;
		padding-bottom: 0;
	}

	.MainNav__logo {
		height: 100%;
		display: flex;
		margin: 0;

		img {
			height: 22px;
		}
	}

	.MainNav__buttons {
		flex-direction: row-reverse;
		padding: 0;
	}

	.MainNav__button {
		padding: 14px 0;
		margin: 0 5px;
		min-height: 20px;
	}

	.MainNav__buttonLabel {
		font-size: 14px;
	}

	.MainNav__guildNotification {
		display: inline-block;
	}

	.MainNav__mobileOverlay {
		display: none;
	}

	.MainNav__link {
		flex-direction: row;
		height: 100%;
		align-items: center;
		font-size: 1.07rem;
		user-select: none;
		height: 100%;
	}

	.MainNav__link:hover {
		background-color: hsla(0,0%,100%,.08);
	}
}

@media (min-width: 1200px) {
	.MainNav {
		padding: 0 20px;
	}

	.MainNav__logo {

		img {
			height: 30px;
		}
	}

	.MainNav__buttonLabel {
		font-size: 18px;
	}
}
