@import '../../../variables.scss';

.ArticlesPage {
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.ArticlesPage__searchBar {
    padding: 10px 0 30px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.ArticlesPage__searchBarField {
    width: 100%;
}

.ArticlesPage__searchResultsDisplay {
    padding: 10px;
}

@media (min-width: 600px) {

    .ArticlesPage__searchBarField {
        max-width: 500px;
    }
}
