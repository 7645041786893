.PaymentMethods {
    padding-bottom: 60px;
}

.PaymentMethods__header {
    font-size: 1.7em;
    font-weight: bold;
    line-height: 1.3em;
    padding-bottom: 15px;
}

.PaymentMethods {
    .card {
        transition: 0.2s ease-in-out;
        &.selected {
            outline: 5px solid #ACCEEC;
            &:hover {
                outline: 5px solid #ACCEEC !important;
            }
        }
    }
}
