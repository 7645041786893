@import '../../../variables.scss';

.ProductTile {
    border: 1px solid $medium-dark-grey;
    cursor: pointer;
    
    color: $primary-font-color;
    filter: $h1-drop-shadow;
    width: 300px;
    height: 250px;
    background-size: cover;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProductTile:hover {
    transform: scale(1.05);
}

.ProductTile__productInfo {
    border: 1px solid $medium-dark-grey;
    background-color: $light-med-grey-opaque-90;
    width: 65%;
    height: 60%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    text-align: center;
}

.ProductTile__title {
    width: 100%;
    font-size: 22px;
    font-weight: bold;
    padding-bottom: 20px;
}

.ProductTile__subtitle {
    width: 100%;
    font-size: 20px;
}