@import '../../../variables.scss';

.SocialButton {
	padding: 8px 14px;
	border-radius: 10px;
	color: white;
    -moz-box-shadow: $standard-box-shadow;
    -webkit-box-shadow: $standard-box-shadow;
	box-shadow: $standard-box-shadow;
	transition: $quick-ease;
}

.SocialButton--twitter {
	background-color: $twitter-blue;
}

.SocialButton--twitter:hover {
	background-color: $twitter-blue-dark;
}

.SocialButton--facebook {
	background-color: $facebook-blue;
}

.SocialButton--facebook:hover {
	background-color: $facebook-blue-dark;
}

.SocialButton--mail {
	background-color: $email-blue;
}

.SocialButton--mail:hover {
	background-color: $email-blue-dark;
}

@media (min-width: 600px) {
	.SocialButton {
		padding: 16px 24px;
	}
}