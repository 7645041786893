.SiteFrame {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	max-width: 2400px;
}

.SiteFrame__overlay {
	position: fixed;
	z-index: 1999;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: rgba($color: #4d4d4d, $alpha: .5);
}

.SiteFrame__content {
	width: 100%;
	flex-shrink: 0;
	min-height: 100vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: space-between;
	background-color: white;
	box-shadow: 0 0 50px rgba(11,6,4,.8), 0 0 100px rgba(11,6,4,.6), 0 0 500px rgba(11,6,4,.3);
	// background-image: linear-gradient(rgb(214, 214, 214), rgb(179, 179, 179));
}

@media (min-width: 900px) {
	.SiteFrame__overlay {
		display: none;
	}
}