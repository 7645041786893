.TeamAsset {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 160px;
}

.TeamAsset__crest {
    width: 160px;
}

.TeamAsset__name {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}