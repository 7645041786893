.InnovaPage {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.InnovaPage__header {
    width: 100%;
    padding-bottom: 40px;
    text-align: center;
}