.HolidayPage {
    padding: 20px 20px 40px;
    font-size: 18px;
}

.HolidayPage__tagline {
    width: 100%;
    text-align: center;
    font-size: 25px;
    position: absolute;
}

.HolidayPage__banners {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0;
    margin-top: 25px;
}

.HolidayPage__banner__left {
    /* margin: 20px; */
    /* background-size: cover; */
    height: 360px;
    width: 290px;
    color: white;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    margin-right: 30px;
    margin-top: 20px;
    margin-bottom: 20px;

}
.HolidayPage__banner__right {
    margin: 20px;
    /* background-size: cover; */
    height: 360px;
    width: 290px;
    color: white;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    margin-left: 42px;
}

.HolidayPage__bannerTitle {
    width: 100%;
    letter-spacing: 5px;
    padding-top: 30px;
    filter: drop-shadow(3px 2px 2px black);
}

.HolidayPage__sacrifice__left {
    /* border: 1px solid rgba(240, 240, 240, 0.2); */
    /* background-color: rgba(0, 0, 0, 0.2); */
    /* width: 230px; */
    /* padding: 10px 0; */
    margin-top: 10px;
    margin-left: 95px;
    -webkit-filter: drop-shadow(3px 2px 2px black);
    filter: drop-shadow(3px 2px 2px black);
    letter-spacing: 3px;
    /* text-align: center; */
    font-weight: 900;
    font-size: 35px;
}

.HolidayPage__sacrifice__right {
    /* border: 1px solid rgba(240, 240, 240, 0.2); */
    /* background-color: rgba(0, 0, 0, 0.2); */
    /* width: 230px; */
    /* padding: 10px 0; */
    margin-top: 10px;
    margin-left: 10px;
    -webkit-filter: drop-shadow(3px 2px 2px black);
    filter: drop-shadow(3px 2px 2px black);
    letter-spacing: 3px;
    text-align: center;
    font-weight: 900;
    font-size: 35px;
}

.HolidayPage__skull {
    height: 200px;
}

.HolidayPage__content {
    padding-top: 20px;
}