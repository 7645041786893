@import '../../../variables.scss';

.AccordionItem {
	width: 100%;
    margin-bottom: 4px;
}

.AccordionItem__handle {
    position: relative;
    display: block;
    float: none;
    width: auto;
    cursor: pointer;
    font-size: 22px;
    letter-spacing: 0.05em;
    color: #15171c;
    padding: 15px 40px 13px 20px;
    line-height: 1.25;
    font-weight: bold;
    vertical-align: middle;
    white-space: normal;
    transition: $quick-ease;
}

.AccordionItem__handle:before {
    content: '';
    transform: rotate(-90deg);
    width: 24px;
    height: 13px;
    position: absolute;
    top: 50%;
    margin: -6px 0 0;
    right: 10px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAANCAMAAACEsl7iAAAAV1BMVEUAAAAVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwVFxwIeZErAAAAHHRSTlMAofjwxCsX6ePc1cu5r6ealIuAe3VAMyUgGxEI8umqtwAAAHNJREFUGNNtjkcOg0AUxf5Ueu/g+58ziRCCIePNs7x6MimJoCZR2OOdD4sSbyn2sO8F1n+3JPfP7nPK0yqy7e5bRnV5TbpevqbUjxMky2lLQnCzwbjfOkMjAS1mFpkNrbzo0M5pOvmjR2t6iTDCKFGGQW4+scgFiWqiq9oAAAAASUVORK5CYII=')
}

.AccordionItem__handle:hover {
    background-color: $light-med-grey;
}

.AccordionItem__content {
	width: 100%;
	display: none;
}

.AccordionItem--show {
	
    .AccordionItem__handle:before {
        transform: rotate(0);
    }

	.AccordionItem__content {
		display: inline-block;
	}	

}

.AccordionItem--arrowLeft {

    .AccordionItem__handle {
        padding-left: 30px;
        white-space: nowrap;
    }

    .AccordionItem__handle:before {
        left: 0;
    }
}

.AccordionItem--gray {
    .AccordionItem__handle {
        background-color: $light-grey;
    }

    .AccordionItem__handle:hover {
        background-color: $light-med-grey;
    }
}
