.AreaScroll {
	position: relative;
	width: 1200px;
	overflow-y: hidden;
	overflow-x: hidden;
	display: flex;
}

.AreaScroll:before {
	content: '';
	background: linear-gradient(to right, rgba(8,9,15,1) 0%, rgba(8,9,15,1) 60%, rgba(8,9,15,0) 85%, rgba(8,9,15,0) 100%);
	height: 100%;
	width: 40px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.AreaScroll:after {
	content: '';
	background: linear-gradient(to left, rgba(8,9,15,1) 0%, rgba(8,9,15,1) 60%, rgba(8,9,15,0) 85%, rgba(8,9,15,0) 100%);
	height: 100%;
	width: 40px;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
}

.AreaScroll__movable {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.AreaScroll__content {
	overflow-y: hidden;
	overflow-x: hidden;
	scroll-behavior: smooth;
	padding: 0 40px;
}

.AreaScroll__button {
	color: white;
	font-size: 250px;
	height: 75px;
	width: 0;
	z-index: 2;
}

.AreaScroll__button:after {
	content: "\02039";
	margin: -5px;
	opacity: 0.65;
}

.AreaScroll__button--right {
	transform: rotate(180deg);
}

@media(min-width: 1600px) {
	.AreaScroll:before {
		display: none;
	}

	.AreaScroll:after {
		display: none;
	}
	.AreaScroll__movable {
		width: calc(100% + 100px);
	}

	.AreaScroll__content {
		padding: 0;
	}

	.AreaScroll__button:after {
		content: '';
	}
}
