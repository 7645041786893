@import "../../../../variables.scss";

.TwitchLander {
    display: flex;
    flex-direction: column; 
    align-items: center;
    font-size: 16px;
    padding: 30px 20px 0;
}

.TwitchLander__body {
    display:  flex;
    align-items: center;
    flex-direction: column;
}

.TwitchLander__tagline {
    padding-bottom: 30px;
    max-width: 900px;
    text-align: center;
}

.TwitchLander__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
    height: 100%;
}

.TwitchLander__header {
    font-size: 24px;
    padding-bottom: 15px;
    font-weight: bold;
}

.TwitchLander__subheader {
    font-size: 18px;
    padding-bottom: 10px;
}

.TwitchLander__step {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
}

.TwitchLander__icon {
    font-size: 20px;
    padding-left: 10px;
}

.TwitchLander__button {
    padding-bottom: 25px;
}

.TwitchLander__twitchAccountString {
    padding-bottom: 10px;
}

@media (min-width: 1200px) {
    .TwitchLander__body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: flex-start;
        justify-items: center;
    }

    .TwitchLander__box:first-child {
        border-right: 1px solid $medium-grey;
        padding: 0 20px 10px 0;
    }

    .TwitchLander__box:nth-child(2) {
        padding: 0 0 10px 20px;
    }

    .TwitchLander__box:last-child {
        max-width: 100%;
        grid-column-end: span 2;
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid $medium-grey;
    }

}
