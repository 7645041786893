.Title {
	font-size: calc(1.1em + 1vw);
    letter-spacing: 4px;
    line-height: 1.1;
    color: #15171c;
    padding-bottom: 20px;
    font-weight: 400;
}

.Title--center {
    width: 100%;
	text-align: center;
}

.Title--bold {
    font-weight: 700;
}

.Title--h1 {
    font-size: calc(1.5em + 1vw);
    padding-bottom: 40px;
}

.Title--h5 {
    font-size: calc(.9em + 1vw);
}