.DecapathonPage {
    padding: 20px 20px 40px;
    font-size: 18px;
}

.DecapathonPage__tagline {
    width: 100%;
    text-align: center;
}

.DecapathonPage__banners {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0;
}

.DecapathonPage__banner {
    margin: 20px;
    background-size: cover;
    height: 330px;
    width: 250px;
    color: white;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
}

.DecapathonPage__bannerTitle {
    width: 100%;
    letter-spacing: 5px;
    padding-top: 30px;
    filter: drop-shadow(3px 2px 2px black);
}

.DecapathonPage__kills {
    border: 1px solid rgba(240, 240, 240, .2);
    background-color: rgba(0, 0, 0, .2);
    width: 230px;
    padding: 10px 0;
    margin-top: 10px;
    margin-left: 10px;
    filter: drop-shadow(3px 2px 2px black);
    letter-spacing: 3px;
}

.DecapathonPage__skull {
    height: 200px;
}

.DecapathonPage__content {
    padding-top: 20px;
}