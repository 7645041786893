@import '../../../variables.scss';

.MotdBox {
    border: 1px solid $minor-font-color;
    background-color: white;
    box-shadow: $standard-box-shadow;
    padding: 5px 10px 10px;
}

.MotdBox__header {
    position: relative;
    min-height: 25px;
    padding: 0 25px 0 10px;
    margin-bottom: 5px;
    color: $minor-font-color;
    font-size: 12px;
    transition: $quick-ease
}

.MotdBox__edit {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 18px;
}

.MotdBox__content {
    width: 100%;
    font-size: 16px;
    white-space: pre-wrap;
    overflow-y: auto;
    min-height: 30px;
    max-height: 143px;
    padding: 5px 10px;
    border: 1px solid $light-grey;
}

.MotdBox__emptyContentString {
    color: $minor-font-color;
}

.MotdBox__textarea {
    height: auto;
    width: 100%;
    resize: none;
}

.MotdBox--canEdit {
    .MotdBox__header:hover {
        cursor: pointer;
        background-color: $light-grey;
    }

    .MotdBox__edit:hover {
        transform: scale(1.15);
    }
}

@media (min-width: 600px) {
    .MotdBox__header {
        text-align: center;
        padding: 0 25px;
    }
}