.ContentBox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: rgb(224, 224, 224);
    border: 1px solid #6b6b6b;
    padding: 20px;
    position: relative;
}

.ContentBox--stoneborn {
    padding: 60px 15px 15px;
    overflow: hidden;
}

.ContentBox--goldBar {
    border-top: none;
    padding: 20px 15px 15px;
}

.ContentBox__topAccent--stoneborn {
    position: absolute;
    top: 0;
}

.ContentBox__topAccent--goldBar {
    position: absolute;
    top: -8px;
    width: 100%;
}

.ContentBox__rightAccent--goldBorder {
    position: absolute;
    height: 100%;
    top: 0;
    right: -8px;
}

.ContentBox__bottomAccent--goldBorder {
    position: absolute;
    width: 100%;
    bottom: -8px;
}

.ContentBox__leftAccent--goldBorder {
    position: absolute;
    height: 100%;
    top: 0;
    left: -8px;
}

.ContentBox__expandableContent {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ContentBox__moreButton {
    cursor: pointer;
    font-size: 18px;
    color: rgb(120, 120, 120);
    text-decoration: underline;
    padding: 15px 15px 5px;
}

.ContentBox__expandedSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    display: none;
    width: 100%;
    position: relative;
    padding-top: 30px;
}

.ContentBox__sectionAccent--stoneborn {
    position: absolute;
    top: -50px;
}

.ContentBox__content {
    width: 100%;
    padding: 5px 0; 
    font-size: 18px;
}

.ContentBox__lessButton {
    cursor: pointer;
    font-size: 18px;
    color: rgb(120, 120, 120);
    text-decoration: underline;
    padding: 15px 15px 5px;
}

.ContentBox--expanded .ContentBox__moreButton {
    display: none;
}

.ContentBox--expanded .ContentBox__expandedSection {
    display: flex;
}

.ContentBox--stoneborn .ContentBox__expandedSection {
    padding-top: 90px;
}

@media (min-width: 600px) {
    .ContentBox {
        padding: 25px;
    }

    .ContentBox--stoneborn {
        padding: 60px 25px 25px;
    }

    .ContentBox--goldBar {
        padding: 25px;
    }
}