@import "../../../variables.scss";

.CartView {
    max-width: 1000px;
    min-width: 70vw;
    background-color: white;
    padding: 30px;
    color: $primary-font-color;
}

.CartView__header {
    text-align: center;
    font-size: $size-24-32;
    line-height: $size-26-34;
    color: $medium-dark-blue;
    font-weight: bold;
    padding-bottom: 30px;
}

.CartView__list {
    border-top: 1px solid $medium-dark-blue;
    margin-bottom: 20px;
}

.CartView__item {
    padding: 10px 5px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    border-bottom: 1px solid $medium-dark-blue;
    font-size: 18px;
}

.CartView__cancel {
    grid-column: 1 / span 2;
    justify-self: flex-end;
    font-size: 18px;
    transition: $quick-ease;
    cursor: pointer
}

.CartView__cancel:hover {
    transform: scale(1.15);
}

.CartView__asset {
    display: none;
}

.CartView__name {
    grid-column: 1 / span 2
}

.CartView__unitPrice {
    grid-column: 1;
    align-self: center;
}

.CartView__quantity {
    grid-column: 2;
}

.CartView__itemTotal {
    display: none;
}

.CartView__emptyCart {
    text-align: center;
    padding: 10px;
    font-size: 18px;
    border-bottom: 1px solid $medium-dark-blue;
}

.CartView__subtotal {
    font-size: 18px;
    text-align: right;
}

.CartView__button {
    padding-top: 15px;
}


@media (min-width: 900px) {

    .CartView {
        min-width: 700px;
    }

    .CartView__item {
        grid-template-columns: auto 1fr auto auto auto;
        align-items: center;
        grid-gap: 0;
        padding: 10px;
    }

    .CartView__cancel {
        grid-column: 5;
        grid-row: 1 / span 2;
        font-size: 24px;
    }

    .CartView__asset {
        grid-column: 1;
        grid-row: 1 / span 2;
        margin-right: 10px;
        display: block;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 105px;
        height: 70px;
        overflow: hidden;
    }

    .CartView__name {
        grid-column: 2;
        align-self: flex-end;
        padding-bottom: 5px;
    }

    .CartView__unitPrice {
        grid-column: 2;
        grid-row: 2;
        align-self: flex-start;
        padding-top: 5px;
    }

    .CartView__quantity {
        grid-column: 3;
        grid-row: 1 / span 2;
        padding: 0 20px;
    }

    .CartView__itemTotal {
        grid-column: 4;
        grid-row: 1 / span 2;
        display: flex;
        justify-content: flex-end;
        min-width: 70px;
        margin-right: 40px;
    }

    .CartView__buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .CartView__button {
        padding: 15px 0 0 10px;
    }
}