.Grid {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}

.Grid__element {
    width: 100%;
    padding-bottom: 20px;
}

@media (min-width: 900px) {
    .Grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }

    .Grid__element {
        align-self: stretch;
        padding-bottom: 0;
    }

    .Grid--gridGap30 {
        grid-gap: 30px;
    }
}

@media (min-width: 1200px) {
    .Grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .Grid--featuredTwo {
        grid-template-columns: repeat(6, 1fr);

        .Grid__element:first-child {
            grid-column-start: 1;
            grid-column-end: span 3;
        }

        .Grid__element:nth-child(2) {
            grid-column-start: 4;
            grid-column-end: span 3;
        }
        
        .Grid__element:nth-child(n + 3) {
            grid-column-end: span 2;
        }
    }

    .Grid--fillBottomRow1 {
        .Grid__element:last-child {
            grid-column-end: span 3;
        }
    }

    .Grid--fillBottomRow2 {
        grid-template-columns: repeat(6, 1fr);

        .Grid__element {
            grid-column-end: span 2;
        }

        .Grid__element:nth-last-child(2) {
            grid-column-end: span 3;
        }

        .Grid__element:last-child {
            grid-column-end: span 3;
        }
    }

    .Grid--featuredAndFillBottom1 {
        .Grid__element:last-child {
            grid-column-end: span 6;
        }
    }

    .Grid--featuredAndFillBottom2 {
        .Grid__element:nth-last-child(2) {
            grid-column-end: span 3;
        }

        .Grid__element:last-child {
            grid-column-end: span 3;
        }
    }

    .Grid--irregularColumns {
        .Grid__element:nth-child(3n - 2) {
            grid-column-end: span 3;
        }

        .Grid__element:nth-child(3n - 1) {
            grid-column-end: span 2;
        }
    }
}