@import '../../../variables.scss';

.SectionHeader {
    cursor: default;
    display: flex;
    justify-content: space-between;
    font-size: $size-32-42;
    line-height: $size-36-48;
    letter-spacing: .1rem;
    color: $secondary-font-color;
}

.SectionHeader__title {
    font-weight: bold;
    align-self: center;
    overflow-wrap: anywhere;
    padding-right: 10px;
}

.SectionHeader__icon {
    padding: 10px;
    cursor: pointer;
    transition: $quick-ease;
}

.SectionHeader__icon:hover {
    color: $secondary-font-color-hover;
    transform: scale(1.05);
}

.SectionHeader--h3 {
    font-size: $size-22-28;
    line-height: $size-24-32;
}

.SectionHeader--h5 {
    font-size: $size-16-20;
    line-height: $size-20-24;
}

@media (min-width: 600px) {
    .SectionHeader--hasIcon {
        .SectionHeader__title {
            padding-right: 40px;
        }
    }
}

@media (min-width: 900px) {
    .SectionHeader--hasIcon {
        .SectionHeader__title {
            padding-right: 100px;
        }
    }
}