.GuildNotificationsPage {

}

.GuildNotificationsPage__search {
    padding: 30px 0 20px;
}

.GuildNotificationsPage__pagination {
    padding-top: 30px;
}