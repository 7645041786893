.Carousel {
	position: relative;
	width: 100%;
}

.Carousel__img {
	width: 100%;
	height: 720px;
	overflow: hidden;
	background-size: cover;
	background-position: center;
}

.Carousel__overlay {
	position: absolute;
	bottom: 0;
	padding: 60px;
	background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Carousel__header {
	font-size: 30px;
	color: #5c807c;
	line-height: 30px;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.Carousel__title {
	font-size: 30px;
	color: #d48d31;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 20px;
	line-height: 1.25;
}

.Carousel__description {
	color: #ffffff;
	font-size: 20px;
	line-height: 1.25;
	margin-bottom: 20px;
}

.Carousel__icons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 20px;
}

.Carousel__icon {
	filter: brightness(0.7);
}

.Carousel__icon:hover {
	filter: brightness(1);
}

.Carousel__icon--selected {
	filter: brightness(1);
}

@media(min-width: 900px) {
	.Carousel__overlay {
		top: 0;
		width: 35%;
		min-width: 250px;
		height: 100%;
	}

	.Carousel--right {
		.Carousel__overlay {
			right: 0;
		}
	}

}

