@import "../../../variables.scss";

.ECSEventsPage {
    color: $primary-font-color;
    font-size: 18px;
    padding: 0 20px;
}

.ECSEventsPage__tagline {
    text-align: center;
    margin-bottom: 30px;
}

.ECSEventsPage__body {
    margin-bottom: 20px;
}

.ECSEventsPage__list {
    padding: 20px 0;
}