@import '../../../variables.scss';

.DataTable--horizontal {
	display: none;
	background-color: white;
}

.DataTable--vertical {	
	background-color: white;
}

.DataTable--vertical:last-child {
	border-bottom: 1px solid $dark-grey;
}

.DataTableVertical__row {
    border-top: 1px solid $dark-grey;
}

.DataTableVertical__field {
	display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0 20px;
}

.DataTableVertical__fieldKey {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    font-weight: bold;
    max-width: 200px;
}

.DataTableVertical__fieldValue {
	display: flex;
    align-items: center;
    justify-content: flex-start;
}

.DataTableVertical__accordion {
    
}

.DataTableVertical__accordionActor {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	background-color: $light-grey;
}

.DataTableVertical__accordionLabel {
	font-size: 16px;
	font-weight: bold;
}

.DataTableVertical__accordionIcon {
	font-size: 18px;
	padding-left: 15px;
}

.DataTableVertical__accordionContent {
	padding: 20px;
}

.DataTable--verticalGold {

	.DataTableVertical__row {
		border-color: $gold;
	}

	.DataTableVertical__fieldKey {
		color: $gold;
	}
}

.DataTable--verticalGold:last-child {
	border-color: $gold;
}

@media (min-width: 600px) {
	.DataTable--vertical {
		display: none;
	}

	.DataTable--horizontal {
		display: block;
		cursor: default;
		border-radius: 2px;
		width: 100%;
		overflow-x: auto;
	}

	.DataTableHorizontal__label {
		display: flex;
		grid-column-end: span 1;
		flex-direction: row;
		font-size: 16px;
		font-weight: bold;
		padding: 10px 10px 15px;
		white-space: nowrap;
	}
	
	.DataTableHorizontal__row {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		align-items: stretch;
		border-bottom: 1px solid $dark-grey;
	}

	.DataTableHorizontal__accordionIcon {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 0 15px;
		min-width: 40px;
	}

	.DataTableHorizontal__accordionItem {
		grid-column-end: span 2;
		padding: 10px 20px 20px;
	}
	
	.DataTableHorizontal__square {
		text-decoration: none;
		padding: 15px 10px;
	}
	
	.DataTableHorizontal--highlightedRow {
		cursor: pointer;
		background-color: $light-grey;
	}
	
	.DataTableHorizontal__labelArrow {
		display: none;
		margin-left: 15px;
	}

	.DataTableHorizontal__label--accordionLabel {
		min-width: 40px;
	}
	
	.DataTableHorizontal__label--sortable .DataTableHorizontal__labelArrow {
		display: inline-block;
	}
	
	.DataTableHorizontal__label--sortAsc .DataTableHorizontal__labelArrow {
		transform: rotate(90deg);
	}
	
	.DataTableHorizontal__label--sortDesc .DataTableHorizontal__labelArrow {
		transform: rotate(-90deg);
	}

	.DataTableHorizontal__end {
		grid-column-end: span 2;
		width: 100%;
	}
	
	.DataTable__grid--3 {
		grid-template-columns: repeat(3, 1fr);

		.DataTableHorizontal__accordionItem {
			grid-column-end: span 3;
		}

		.DataTableHorizontal__end {
			grid-column-end: span 3;
		}
	}

	.DataTable__grid--4 {
		grid-template-columns: repeat(4, 1fr);

		.DataTableHorizontal__accordionItem {
			grid-column-end: span 4;
		}

		.DataTableHorizontal__end {
			grid-column-end: span 4;
		}
	}

	.DataTable__grid--5 {
		grid-template-columns: repeat(5, 1fr);

		.DataTableHorizontal__accordionItem {
			grid-column-end: span 5;
		}

		.DataTableHorizontal__end {
			grid-column-end: span 5;
		}
	}

	.DataTable__grid--6 {
		grid-template-columns: repeat(6, 1fr);

		.DataTableHorizontal__accordionItem {
			grid-column-end: span 6;
		}

		.DataTableHorizontal__end {
			grid-column-end: span 6;
		}
	}

	.DataTable__grid--7 {
		grid-template-columns: repeat(7, 1fr);

		.DataTableHorizontal__accordionItem {
			grid-column-end: span 7;
		}

		.DataTableHorizontal__end {
			grid-column-end: span 7;
		}
	}

	.DataTable__grid--accordion3 {
		grid-template-columns: repeat(2, 1fr) auto;

		.DataTableHorizontal__accordionItem {
			grid-column-end: span 3;
		}

		.DataTableHorizontal__end {
			grid-column-end: span 3;
		}
	}

	.DataTable__grid--accordion4 {
		grid-template-columns: repeat(3, 1fr) auto;

		.DataTableHorizontal__accordionItem {
			grid-column-end: span 4;
		}

		.DataTableHorizontal__end {
			grid-column-end: span 4;
		}
	}

	.DataTable__grid--accordion5 {
		grid-template-columns: repeat(4, 1fr) auto;

		.DataTableHorizontal__accordionItem {
			grid-column-end: span 5;
		}

		.DataTableHorizontal__end {
			grid-column-end: span 5;
		}
	}

	.DataTable__grid--accordion6 {
		grid-template-columns: repeat(5, 1fr) auto;

		.DataTableHorizontal__accordionItem {
			grid-column-end: span 6;
		}

		.DataTableHorizontal__end {
			grid-column-end: span 6;
		}
	}

	.DataTable__grid--accordion7 {
		grid-template-columns: repeat(6, 1fr) auto;

		.DataTableHorizontal__accordionItem {
			grid-column-end: span 7;
		}

		.DataTableHorizontal__end {
			grid-column-end: span 7;
		}
	}

	.DataTable--horizontalGold {
		.DataTableHorizontal__label {
			color: $gold;
		}
	
		.DataTableHorizontal__row {
			border-color: $gold;
		}
	}
}