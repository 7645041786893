.SocialButtons {
	display: flex;
	align-items: center;
}

.SocialButtons__text {
	display: none;
	font-style: italic;
	justify-self: center;
}

.SocialButtons__buttons {
	display: flex;
}

.SocialButtons__button {
	margin-left: 15px;
}

@media (min-width: 600px) {
	.SocialButtons__text {
		display: block;
	}

	.SocialButtons__button {
		margin-left: 10px;
	}
} 
