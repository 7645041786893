.CampaignsPage {
    padding: 20px 20px 40px;
    font-size: 18px;
}

.CampaignsPage__tagline {
    width: 100%;
    text-align: center;
}

.CampaignsPage__content {
    padding-top: 40px;
}