@import "../../../variables.scss";

.ProductCard {
    margin: 15px;
    width: 320px;
    height: calc(100% - 30px);
    border: 1px solid $minor-font-color;
    box-shadow: $standard-box-shadow;
    background-color: $light-med-grey;
    position: relative;
}

.ProductCard__capsules {
    position: absolute;
    top: -15px;
    left: 5px;
    display: flex;
}

.ProductCard__capsule {
    padding: 0 5px;
    z-index: 5;
}

.ProductCard__content {
    position: relative;
    overflow: hidden;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
}

.ProductCard__imgContainer {
    overflow: hidden;
    cursor: pointer;
}

.ProductCard__img {
    height: 212px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: $quick-ease;
    position: relative;
    
}

.ProductCard__infoBlock {
    color: white;
    background-color: $dark-red-opaque-80;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 10px 0 10px 10px;
    width: 50px;
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 160px;
    transition: $quick-ease;
    cursor: pointer;
}

.ProductCard__floatingButton {
    position: absolute;
    right: 25px;
    top: 20px;
    cursor: pointer;
    height: 45px;
    width: 45px;
}

.ProductCard__body {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ProductCard__name {
    color: $secondary-font-color;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 5px;
}

.ProductCard__price {
    font-size: 16px;
    color: $secondary-font-color;
}

.ProductCard__basePrice {
    text-decoration: line-through;
    color: darkred;
    padding-right: 10px;
}

.ProductCard__button {
    height: 40px;
    margin-top: 15px;
}

@media (min-width: 900px) {
    .ProductCard__infoBlock {
        right: -50px;
    }

    .ProductCard:hover {
        .ProductCard__img {
            -webkit-transform: scale(1.05,1.05);
        }   
    
        .ProductCard__infoBlock {
            transform: translateX(-50px);
        }
    }
}