@import './variables.scss';
@import './semantic-overwrite.scss';
@import './swal-overwrite.scss';


h1 {
    font-size: calc(1.5em + 1vw);
}

h1.h1__large {
    font-size: calc(2.5em + 1vw);
}

h2 {
    font-size: calc(1.3em + 1vw);
}

h3 {
    font-size: calc(1.1em + 1vw);
}

h4 {
    font-size: calc(0.7em + 1vw);
}

p {
    font-size: 1em;
}


.Body--overflowVisible {
    overflow: visible;
}

.Body--overflowHidden {
    overflow: hidden;
}

.Spacer__vertical10px {
    height: 10px;
}

.Store__Ribbon {
    position: absolute;
    color: white;
    background-color: rgba(200,0,0, .9);
    width: 100%;
    border: 1px transparent;
    padding: 10px 10px 10px 25px;
    transform: rotate(20deg) translate(40px);
    z-index: 10;
    text-align: center;
    user-select: none;
    font-size: 18px;
    max-width:300px;
    right: 0;
}

.Store__Ribbon-container {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100px;
    pointer-events: none;
}

@media (min-width: 600px) {
    h4 {
        font-size: calc(.4em + 1vw)
    }
}