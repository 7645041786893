@import '../../../variables.scss';

.ReviewItems {
    padding-bottom: 50px;

}

.ReviewItems__header {
    font-weight: bold;
    width: 100%;
    padding-bottom: 20px;
    font-size: 1.8rem;
    line-height: 2rem;
}

.ReviewItems__list {
    border-top: 1px solid $light-med-grey;
}

.ReviewItems__itemDetail {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $light-med-grey;
    font-size: 1.6rem;
    line-height: 1.8rem;
}

.ReviewItems__itemName {
    padding-right: 10px;
}

.ReviewItems__paragraph {
    padding: 20px 40px 0;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
}