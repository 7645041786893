@import '../../../variables.scss';

.MediaTile {
    font-size: 16px;
    position: relative;
	height: 255px;
    background-size: cover;
    background-position: center;
    box-shadow: 3px 3px 8px black;
}

.MediaTile__footer {
    position: absolute;
    bottom: 0;
	padding: 10px;
	width: 100%;
    height: min-content;
    color: #dbdbdb;
    padding: 14px 20px;
    width: 100%;
    padding-right: 20%;
    filter: $h3-drop-shadow;
}

.MediaTile__shadow {
    position: absolute;
    bottom: 0;
    height: 54%;
    width: 100%;
    background: linear-gradient(to top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 85%, rgba(0,0,0,0) 100%);
}

.MediaTile__title {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 22px;
    letter-spacing: 4px;
    color: white;
    line-height: 1.25;
    margin-bottom: 5px;
    word-break: break-word;
}

.MediaTile__date {
	font-size: 12px;
}

.MediaTile__description {
	margin-top: 35px;
	font-size: 100%;
    color: #777;
    display: none;
}

.MediaTile__img {
    min-width: 100%;
}

.MediaTile__img img {
    width: 100%;
}

.MediaTile--small {
    height: 235px;
}

.MediaTile--small .MediaTile__title {
    font-size: 18px;
}

.MediaTile--small .MediaTile__description {
    font-size: 14px;
}
