.GuildLeaderToolsPage {
    font-size: 16px;
}

.GuildLeaderToolsPage__discordManagement {
    padding: 20px 0 50px;
}

.GuildLeaderToolsPage__discordNotConnected {

}

.GuildLeaderToolsPage__buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.GuildLeaderToolsPage__buttons {
    padding: 0 10px 10px;
}

.GuildLeaderToolsPage__sectionSpacer {
    height: 10px;
}

.GuildLeaderToolsPage__settingsForm {
    padding: 5px 0 25px;
}

.GuildLeaderToolsPage__field {
    padding: 15px 0;
}

.GuildLeaderToolsPage__formButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-bottom: 15px;
}

.GuildLeaderToolsPage__button {
    padding: 10px;
}

.GuildLeaderToolsPage__formMessage {
    padding-bottom: 10px;
}

.GuildLeaderToolsPage__successionRules {
    padding: 20px 0;
}

.GuildLeaderToolsPage__transferDropdown {
    width: 100%;
    padding: 5px;
    cursor: pointer;
}

.GuildLeaderToolsPage__modifyCrest {
    padding: 15px 0 30px;
}

@media (min-width: 600px) {
    .GuildLeaderToolsPage__buttons {
        justify-content: flex-end;
    }
}