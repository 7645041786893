@import "../../../../variables.scss";

.RazerRegistration {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    padding: 30px 20px 0;
}

.RazerRegistration__ribbon {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
}

.RazerRegistration__ribbonAsset {
    max-width: 280px;
}

.RazerRegistration__mobileRibbon {
    width: 100%;
    padding: 20px 0;
}

.RazerRegistration__content {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.RazerRegistration__tagline {
    padding-bottom: 40px;
    max-width: 900px;
}

.RazerRegistration__box {
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
}

.RazerRegistration__title {
    width: 100%;
    font-weight: bold;
    font-size: 24px;
    padding: 30px 0 20px;
}

.RazerRegistration__gameLinks {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 15px;
    justify-items: flex-start;
    align-items: center;
}

.RazerRegistration__asset {
    width: 100%;
    max-width: 600px;
    padding-bottom: 30px;
}

.RazerRegistration__callout {
    width: 100%;
    border: 1px solid $medium-grey;
    border-radius: 2px;
    padding: 10px;
    margin-bottom: 30px;
}

.RazerRegistration__header {
    display: flex;
    justify-content: center;
    font-size: 24px;
    padding-bottom: 25px;
}

.RazerRegistration__text {
    width: 100%;
    padding-bottom: 10px;
    font-size: 18px;
}

@media (min-width: 900px) {
    .RazerRegistration__ribbon {
        padding: 30px 0;
    }

    .RazerRegistration__ribbonAsset {
        max-width: 360px;
    }

    .RazerRegistration__mobileRibbon {
        display: none;
    }
}

@media (min-width: 1200px) {
    .RazerRegistration__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px;
        align-items: flex-start;
    }

    .RazerRegistration__ribbon {
        padding: 35px 0;
    }

    .RazerRegistration__ribbonAsset {
        max-width: 440px;
    }
}