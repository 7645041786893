.ShopPage {

}

.ShopPage__header {
    font-size: 18px;
    padding: 0 10px 30px;
}

.ShopPage__frame {

}

.ShopPage__menu {
    padding-bottom: 30px;
}

.ShopPage__crowns {
    width: min-content;
    padding: 0 20px 20px;
}

.ShopPage__search {
    padding: 0 20px 20px;
}

.ShopPage__body {
    padding: 0 20px;
}

.ShopPage__scrollTo {
    display: flex;
    justify-content: flex-end;
}


@media (min-width: 1200px) {
    .ShopPage__header {
        padding: 0 10px 40px;
    }

    .ShopPage__frame {
        padding: 0 20px;
        display: grid;
        grid-template-columns: 200px 1fr;
        grid-template-rows: auto 1fr;
        grid-gap: 20px;
    }

    .ShopPage__menu {
        grid-column: 1;
        grid-row: 2;
        padding-bottom: 0;
        align-self: flex-start;
    }

    .ShopPage__crowns {
        width: 100%;
        padding: 0;
        &.ShopPage__crowns-guild {
            margin-top: 20px;
        }
    }

    .ShopPage__search {
        grid-column: 2;
        grid-row: 1;
        padding: 0;
        height: min-content;
    }

    .ShopPage__body {
        padding: 0;
        grid-column: 2;
        grid-row: 1 / span 2;
        padding-top: 80px;
    }
}
