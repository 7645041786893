@import "../../../../variables.scss";

.EditMotdModal {
    @include genericModalSetup()
}

.EditMotdModal__header {
    font-size: 26px;
    line-height: 28px;
    color: $secondary-font-color;
    text-align: center;
    padding-bottom: 25px;
    font-weight: bold;
}

.EditMotdModal__editField {
    padding-bottom: 10px;
    .FieldInput__box {
        height: 140px;
        transition: 0.1s ease-in-out;
    }
    &.limitReached {
        .FieldInput__box {
            background-color: rgba(244,1,5, 0.5)
        }
    }
}

.EditMotdModal__char-count {
    margin-bottom: 5px;
    @media (min-width: 900px) {
        position: absolute;
        bottom: 20px;
        left: 20px;
        margin-bottom: 0;
    }
}

.EditMotdModal__buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.EditMotdModal__button {
    padding: 10px 0 0 10px;
}

@media (min-width: 600px) {
    .EditMotdModal {
        min-width: 500px;
    }
}

@media (min-width: 900px) {
    .EditMotdModal {
        width: 600px;
    }
}
