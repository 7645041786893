@import '../../../variables.scss';

.Pill {
    display: flex;
    width: min-content;
    padding: 8px 12px;
    border-radius: 16px;
    background-color: $secondary-font-color;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    white-space: nowrap;
    box-shadow: $standard-box-shadow;
}

.Pill:hover {
    transform: scale(1.05);
}

.Pill__symbol {
    font-weight: bold;
    font-size: 14px;
    padding-right: 5px; 
}

.Pill--large {
    padding: 10px 14px;
    border-radius: 18px;
    font-size: 14px;

    .Pill__symbol {
        font-size: 18px;
    }
}

.Pill--small {
    padding: 4px 10px;
    border-radius: 12px;
    font-size: 10px;

    .Pill__symbol {
        font-size: 12px;
    }
}

.Pill--disabled {
    cursor: default;
}

.Pill--disabled:hover {
    transform: none;
}