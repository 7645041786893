.Media {
    max-width: 100%;
}

.Media__footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
	width: 100%;
    color: #777;
    background: #f9f9f9;
    padding: 14px 20px;
    min-height: 100px;
    bottom: 0;
}

.Media__text {
    width: 100%
}

.Media__title {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 4px;
    color: #30556b;
    line-height: 1.25;
    margin-bottom: 5px;
    padding-right: 30px;
    padding-bottom: 10px;
}

.Media__img img {
    width: 100%;
}

.Media__img iframe {
   position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

.Media--video .Media__img {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    min-width: 100%; 
    height: 0;
    overflow: hidden;
}

.Media__social {
    justify-self: right;
}

@media (min-width: 600px) {
    .Media__title {
        font-size: 22px;
    }
}


@media (min-width: 900px) {
    .Media__footer {
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .Media--video .Media__img {
        min-width: 800px; 
    }
}

@media (min-width: 1200px) {

    .Media--video .Media__img {
        min-width: 1100px; 
    }
}