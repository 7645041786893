.ShippingCollectionForm {
    display: flex;
    justify-content: center;
}

.ShippingCollectionForm__form {
    max-width: 700px;
    width: 100%;
}

.ShippingCollectionForm__header {
    font-size: 22px;
    font-weight: bold;
    padding-bottom: 15px;
}

.ShippingCollectionForm__field {
    padding-bottom: 20px;
}

.ShippingCollectionForm__formMessage {
    padding-top: 10px;
}

.ShippingCollectionForm__button {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
}