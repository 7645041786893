.GuildManagementInterface {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.GuildManagementInterface__motd {
    margin-bottom: 10px;
    width: 100%;
    order: 3;
    padding: 0 20px;
}

.GuildManagementInterface__crest {
    order: 2;
    padding: 10px 0;
}

.GuildManagementInterface__navigation {
    width: 100%;
    order: 1
}

.GuildManagementInterface__pageContent {
    width: 100%;
    order: 4;
    padding: 20px 20px 0;
}

@media (min-width: 600px) {
    .GuildManagementInterface {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 210px 1fr;
        grid-template-rows: auto auto auto;
    }

    .GuildManagementInterface__motd {
        grid-column: 2;
        grid-row: 2;
        align-self: center;
        padding: 0 20px 0 0;
    }

    .GuildManagementInterface__crest {
        grid-column: 1;
        grid-row: 2;
        padding: 0 0 0 20px;
    }

    .GuildManagementInterface__navigation {
        grid-column: span 2;
        grid-row: 1;
    }

    .GuildManagementInterface__pageContent {
        grid-column: span 2;
        grid-row: 3;
        padding: 0 20px;
    }
}

@media (min-width: 1200px) {
    .GuildManagementInterface {
        grid-gap: 30px;
        grid-template-rows: auto auto 1fr;
    }

    .GuildManagementInterface__motd {
        grid-column: span 2;
        grid-row: 1;
        padding: 0 20px;
    }

    .GuildManagementInterface__crest {
        grid-column: 1;
        grid-row: 2;
    }

    .GuildManagementInterface__navigation {
        grid-column: 1;
        grid-row: 3;
        padding-left: 20px;
    }

    .GuildManagementInterface__pageContent {
        grid-column: 2;
        grid-row: 2 / span 2;
        padding: 0 20px 0 0;
    }
}