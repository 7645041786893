@import '../variables.scss';

.ErrorBoundary {
    width: 100%;
    height: 100vh;
    background-image: $vertical-light-to-dark;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ErrorBoundary__content {
    margin-top: 15vw;
    max-width: 700px;
    border: 2px solid $primary-font-color;
    padding: 40px;
    color: $primary-font-color;
}

@media (min-width: 900px) {
    .ErrorBoundary__content {
        margin-top: 200px;
    }
}