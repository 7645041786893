@import "../../../../variables.scss";

.GuildInvitePlayerModal {
    @include genericModalSetup()
}

.GuildInvitePlayerModal__header {
    font-size: 26px;
    color: $secondary-font-color;
    text-align: center;
    padding-bottom: 25px;
    font-weight: bold;
}

.GuildInvitePlayerModal__buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.GuildInvitePlayerModal__button {
    padding: 10px 0 0 10px;
}

@media (min-width: 600px) {
    .GuildInvitePlayerModal {
        min-width: 450px;
    }
}