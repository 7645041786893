.GoldBar {
    height: min-content;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.GoldBar__img {
    height: 15px;
    width: 66px;
}

.GoldBar__bar {
    width: 100%;
    height: 15px;
}

.GoldBar__centerImg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 30px;
    width: 36px;
    margin-top: 6px;
}

.GoldBar--h3 {

    .GoldBar__img {
        height: 10px;
        width: 40px;
    }

    .GoldBar__bar {
        height: 10px
    }

    .GoldBar__centerImg {
        height: 18px;
        width: 22px;
        margin-top: 2px;
    }

}

.GoldBar--vertical {
    height: 100%;
    width: min-content;
    flex-direction: column;

    .GoldBar__img {
        height: 66px;
        width: 15px;
    }

    .GoldBar__bar {
        width: 15px;
        height: 100%;
        margin-right: 2px;
    }

    .GoldBar__centerImg {
        margin-top: 0;
    }
}

.GoldBar--h3Vertical {
    height: 100%;
    width: min-content;
    flex-direction: column;

    .GoldBar__img {
        height: 40px;
        width: 10px;
    }

    .GoldBar__bar {
        height: 100%;
        width: 10px;
        margin-right: 1px;
    }

    .GoldBar__centerImg {
        height: 18px;
        width: 22px;
    }
}

.GoldBar--vertical {
    flex-direction: column;
    height: 100%;
    width: min-content;

    .GoldBar__center {
        flex-direction: column;
        background-repeat: repeat-y;
        width: min-content;
    }
}
