@import "../../../variables.scss";

.ScrollToPositionButton {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
}

.ScrollToPositionButton__label {
    font-size: 18px;
    font-weight: bold;
    color: $primary-font-color;
    padding-right: 8px;
}

.ScrollToPositionButton__arrow {
    transition: $quick-ease;
    color: white;
    font-size: 20px;
    background-color: $medium-blue;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ScrollToPositionButton:hover {
    .ScrollToPositionButton__arrow {
        transform: scale(1.1);
    }
}