@import '../../../variables.scss';

.ShoppingCart {
    cursor: pointer;
    transition: $quick-ease;
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    background-color: $medium-blue;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    box-shadow: $standard-box-shadow;
}

.ShoppingCart__icon {
    color: white;
    font-size: 1.6em;
}

.ShoppingCart__count {
    display: flex;
    justify-content: center;
    width: 24px;
    height: 24px;
    position: absolute;
    top: -4px;
    left: -4px;
    padding-top: 2px;
    border-radius: 12px;
    background-color: $medium-orange;
    color: white;
    font-size: .9em;
    font-weight: bold;
}

.ShoppingCart:hover {
    transform: scale(1.05)
}

.ShoppingCart--show {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 900px) {
    .ShoppingCart {
        bottom: 100px;
        right: 40px;
    }
}

@media (min-width: 1700px) {
    .ShoppingCart {
        right: calc(50vw - 770px);
    }

    .ShoppingCart--homePage {
        right: 40px;
    }
}