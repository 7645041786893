.AcceptTermsPage {
    display: flex;
    justify-content: center;
    padding: 30px 20px 0;
}

.AcceptTermsPage__form {
    max-width: 600px;
}

.AcceptTermsPage__header {
    padding-bottom: 10px;
    width: 100%;
}

.AcceptTermsPage__field {
    padding: 10px 0;
}

.AcceptTermsPage__additionalAgreements {
    padding-top: 30px;
}

.AcceptTermsPage__button {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}