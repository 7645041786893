.CreateGuildPage {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.CreateGuildPage__form {
    width: 100%;
    max-width: 900px;
}

.CreateGuildPage__field {
    padding: 10px 0;
}

.CreateGuildPage__formMessage {
    padding-bottom: 10px;
}

.CreateGuildPage__buttons {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
}