.CrowCodesLander {
    padding: 0 20px;
    font-size: 18px;
}

.CrowCodesLander__panel {
    padding-bottom: 20px;
}

.CrowCodesLander__tagline {
    text-align: center;
    padding-bottom: 30px;
}

.CrowCodesLander__successMsg {
    display: flex;
    align-items: center;
    color: green;
    padding-bottom: 15px;
}

.CrowCodesLander__icon {
    padding-left: 10px;
}

.CrowCodesLander__code {
    font-size: 20px;
    line-height: 22px;
    padding-bottom: 20px;
}

.CrowCodesLander__content {
    padding-bottom: 30px;
}

.CrowCodesLander__subheader {
    padding-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
}

@media(min-width: 900px) {
    .CrowCodesLander__body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
    }

    .CrowCodesLander__panel {
        padding-bottom: 0;
    }
}