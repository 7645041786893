@import '../../variables.scss';

.Checkout {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 16px;
    padding: 20px;
}
