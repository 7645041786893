@import '../../../variables.scss';

.PromoListItem {
    background-position: center;
    background-size: cover;
    width: 100%;
    padding: 0 5%;
    height: 110px;
    display: flex;
    align-items: center;
    box-shadow: $standard-box-shadow;
}

.PromoListItem__title {
    position: relative;
    color: white;
    font-size: 30px;
    line-height: 32px;
    filter: $h1-drop-shadow;
}

.PromoListItem__titleBlur {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    @include BlurBackgroundEffect(20px);
}

@media (min-width: 900px) {
    .PromoListItem__title {
        font-size: 38px;
        line-height: 40px;
    }
}