@import "../../../variables.scss";

.Modal {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 30px;
	width: 100vw;
	height: 100vh;
	z-index: 9999;
	overflow-y: auto;
	background-color: $dark-grey-opaque-80;
	max-width: 2400px;
}

.Modal__content {
	position: relative;
	margin-top: 30px;
	max-width: calc(100vw - 60px);
	max-height: calc(100vh - 60px);
}

.Modal__close {
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: -30px;
	right: -5px;
	color: white;
	font-size: 32px;
	font-weight: bold;
	transition: $quick-ease;
	cursor: pointer;
}

.Modal__close:hover {
	transform: scale(1.25);
}

@media (min-width: 600px) {
	.Modal {
		padding: 40px;
	}

	.Modal-content {
		margin-top: 0;
	}
	
	.Modal__close {
		right: -30px;
	}
}
