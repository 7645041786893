.CategoryPage {

}

.CategoryPage__header {
    padding-bottom: 30px;
}

.CategoryPage__section {
    padding-bottom: 40px;
}

.CategoryPage__subheader {
    padding-bottom: 15px;
}

.CategoryPage__terms {
    padding-top: 20px;
    font-size: 12px;
  }