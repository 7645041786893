@import "../../../variables.scss";

.ComparisonTable {
    display: grid;
    color: $primary-font-color;
    font-size: 14px;
    overflow-y: auto;
}

.ComparisonTable__headerCell {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 10px 12px;
    min-width: 60px;
}

.ComparisonTable__label {
    text-align: center;
    font-weight: bold;
}

.ComparisonTable__labelCell {
    border: .5px solid $medium-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    min-width: 100px;
    background-color: white;
}

.ComparisonTable__rowLabel {
    width: 100%;
}

.ComparisonTable__bodyCell {
    border: .5px solid $medium-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    min-width: 60px;
    background-color: white;
}

.ComparisonTable--columns6 {
    grid-template-columns: repeat(6, auto);
}

.ComparisonTable--columns7 {
    grid-template-columns: repeat(7, auto);
}

.ComparisonTable--columns8 {
    grid-template-columns: repeat(8, auto);
}

.ComparisonTable--columns9 {
    grid-template-columns: repeat(9, auto);
}

.ComparisonTable--columns10 {
    grid-template-columns: repeat(10, auto);
}

.ComparisonTable--columns11 {
    grid-template-columns: repeat(11, auto);
}

.ComparisonTable--columns12 {
    grid-template-columns: repeat(12, auto);
}

.ComparisonTable--columns13 {
    grid-template-columns: repeat(13, auto);
}

.ComparisonTable--columns14 {
    grid-template-columns: repeat(14, auto);
}

// @media (min-width: 900px)