.FeaturedPage {

}

.FeaturedPage__header {
    padding-bottom: 30px;
}

.FeaturedPage__section {
    padding-bottom: 40px;
}

.FeaturedPage__subheader {
    padding-bottom: 15px;
}