.GuildDetail {
	display: flex;
	flex-wrap: wrap;
}

.GuildDetail__names {
	display: flex;
}

.GuildDetail__displayName {
	font-size: 18px;
	text-transform: uppercase; 
}

.GuildDetail__name {
	color: gray;
	text-transform: uppercase;
}

.GuildDetail__field {
	display: flex;
	padding: 10px;
}

.GuildDetail__label {
	font-weight: 700;
	white-space: nowrap;
}

.GuildDetail__fieldValue {
	padding-left: 10px;
}

@media (min-width: 900px) {
	.GuildDetail {
		flex-wrap: nowrap;
	}
}
