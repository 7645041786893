@import '../../../variables.scss';

.CheckoutSolutionDisplay {

}

.CheckoutSolutionDisplay__reviewShipping {
    padding-bottom: 50px;
}

.CheckoutSolutionDisplay__shippingAddress {
    padding-left: 20px;
    font-size: 16px;
}

.CheckoutSolutionDisplay__backButton {
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.CheckoutSolutionDisplay__header {
    font-weight: bold;
    width: 100%;
    padding-bottom: 20px;
    font-size: 1.8rem;
    line-height: 2rem;
}


@media (min-width: 600px) {
    .CheckoutSolutionDisplay__reviewShipping {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .CheckoutSolutionDisplay__shippingAddress {
        padding: 0 30px;
    }

    .CheckoutSolutionDisplay__backButton {
        padding-top: 0;
        display: block;
    }
}
