@import '../../../variables.scss';

.UpdateSubscriptionTypeModal {
    @include genericModalSetup();
}

.UpdateSubscriptionTypeModal__header {
    @include genericModalHeader();
}

.UpdateSubscriptionTypeModal__buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-top: 10px;
}

.UpdateSubscriptionTypeModal__button {
    padding-left: 10px;
    padding-top: 10px;
}