@import '../../../variables.scss';

.WarStoriesPage {
	font-size: 16px;
	color: $secondary-font-color;
	padding: 0 15px 10px;
}

.WarStoriesPage__description {
	width: 100%;
	text-align: center;
}

.WarStoriesPage__search {
	display: flex;
	flex-wrap: wrap;
	padding-top: 30px;
}

.WarStoriesPage__searchBar {
	width: 100%;
	padding-bottom: 10px;
}

.WarStoriesPage__noResultsText {
	padding: 30px 10px 0;
    width: 100%;
    text-align: center;
}

.WarStoriesPage__content {
	padding: 30px 0 20px;
}

.WarStoriesPage__storiesGrid {
	padding-top: 10px;
}

.WarStoriesPage__stories {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 30px;
	padding: 30px 0;
}

@media (min-width: 600px) {
	.WarStoriesPage__search {
		justify-content: flex-end;
	}

	.WarStoriesPage__searchBar {
		max-width: 500px;
	}
}

@media (min-width: 900px) {
	.WarStoriesPage {
		font-size: 18px;
		padding-top: 20px;
	}

	.WarStoriesPage__stories {
		grid-template-columns: repeat(4, 1fr);
	}

	.WarStoriesPage__story {
		grid-column: span 2;
	}

	.WarStoriesPage__stories--odd .WarStoriesPage__story:first-child:nth-child(odd) {
		grid-column: 2 / span 2;
	}
}