@import '../../../variables.scss';

.ECSLandingPage {
    padding: 20px 20px 0;
    font-size: 18px;
    color: $primary-font-color;
}

.ECSLandingPage__tagline {
    text-align: center;
    margin-bottom: 30px;
}

.ECSLandingPage__body {
    margin-bottom: 20px;
}

.ECSLandingPage__footer {
    padding-top: 40px;
}