.external__alert_info {
  font-style: italic;
  color: #5f5f5f;
  .external__alert_login {
    color: #4e4e4e;
  }
}



.external__alert_login {
  color: #5f5f5f;
}

.external__alert_title {
  padding: 5px;
}

.external__message {
  margin-top: 40px;
}

.AccountDetails {
  padding-top: 60px;
}

.AccountDetails__page {
  padding: 0 20px;
}

@media (min-width: 900px) {
  .AccountDetails {
    padding-top: 100px;
  }
}

@media (min-width: 1200px) {
  .AccountDetails {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 230px 1fr;
    grid-gap: 30px;
  }

  .AccountDetails__menu {
    margin-left: 20px;
  }
}