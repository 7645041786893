@import "../../../../variables.scss";

.RequestToJoinGuildModal {
    @include genericModalSetup()
}

.RequestToJoinGuildModal__header {
    @include genericModalHeader()
}

.RequestToJoinGuildModal__buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-top: 20px;
}

.RequestToJoinGuildModal__button {
    padding: 10px 0 0 10px;
}