@import "../../../../variables.scss";

.GiftToButton__modal {
    @include genericModalSetup()
}

.GiftToButton__modalHeader {
    font-size: 26px;
    color: $secondary-font-color;
    text-align: center;
    padding-bottom: 25px;
    font-weight: bold;
}

.GiftToButton__modalButtons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-top: 20px;
}

.GiftToButton__modalButton {
    padding: 10px 0 0 10px;
}