.AccountVipPage__block {
    margin-bottom: 20px;
}

.AccountVipPage__field {
  padding-bottom: 10px;
  @media (max-width: 1000px) {
    &.AccountVipPage__vip-token-display {
      .DisplayField--columnal {
        display: flex;
        .DisplayField__label {
          width: 75%;
          max-width: 300px;
        }
        .DisplayField__value {
          width: 10%;
        }
      }
    }
  }
}

.AccountVipPage__buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.AccountVipPage__button {
  padding: 5px 10px 5px 0;
}

.AccountVipPage__terms {
  padding-top: 20px;
  font-size: 12px;
}

.AccountVipPage__banner {
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  border-radius: 5px;
}

.AccountVipPage__banner-active {
  background: #c0e0c0;
  border: 1px solid #6e8e6e;
}

.AccountVipPage__banner-inactive {
  background: #ead5dc;
  border: 1px solid #8e6e74;
}


.AccountVipPage__banner-paused {
  border: 1px solid #8e6e74;
  background: rgb(50,50,50);
  color: #e6e6e6;
}

.AccountVipPage__banner-chargepending {
  border: 1px solid #8e6e74;
  background: rgb(157, 52, 52);
  color: #e6e6e6;
}
