.RegistrationPage {
    width: 100%;
}

.RegistrationPage p {
    width: 100%;
}

.RegistrationPage__marketing {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    max-width: 1100px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.RegistrationPage__marketingText {
    max-width: 400px;
    font-weight: bold;
    color: white;
    padding: 10px 20px;
    z-index: 2;
    position: relative;
}

.RegistrationPage__marketingTextBlur {
    background: rgba(0, 0, 0, .6);
    -webkit-filter: blur(50px);
    filter: blur(50px);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}

.RegistrationPage__marketingText div {
    color: white;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    line-height: 32px;
}

@media (min-width: 600px) {
    .RegistrationPage__marketing {
        height: 500px;
    }

    .RegistrationPage__marketingText {
        max-width: 510px;
    }

    .RegistrationPage__marketingText div {
        font-size: 26px;
    }
}