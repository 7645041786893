@import '../../../variables.scss';

.Button {
	padding: 10px;
    position: relative;
	border-radius: 3px;
	background-color: $medium-blue;
    display: inline-block;
	white-space: nowrap;
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    padding: 16px 20px;
    line-height: 1;
    font-weight: 700;
    box-shadow: $standard-box-shadow;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    transition: $quick-ease;
}

.Button:hover {
    background-color: $medium-dark-blue;
}

.Button__loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(233, 233, 233, .2);
}

.Button__spinner {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
  }
  .Button__spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: Button__spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .Button__spinner div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .Button__spinner div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .Button__spinner div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes Button__spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.Button--small {
    padding: 5px 10px;
    font-size: 12px;

    .Button__loadingOverlay {
        padding-top: 5px;
    }

    .Button__spinner {
        height: 20px;
        width: 20px;
    }

    .Button__spinner div {
        height: 16px;
        width: 16px;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: Button__spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
}

.Button--medium {
    padding: 10px 15px;
    font-size: 16px;

    .Button__loadingOverlay {
        padding-top: 8px;
    }

    .Button__spinner {
        height: 30px;
        width: 30px;
    }

    .Button__spinner div {
        height: 24px;
        width: 24px;
        border: 6px solid #fff;
        border-radius: 50%;
        animation: Button__spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
}

.Button--large {
    padding: 21px 32px;
    font-size: 27px;
    box-shadow: $large-box-shadow;
}

.Button--green {
    background-color: $medium-green;
    color: white;
}

.Button--green:hover {
    background-color: $medium-dark-green;
}

.Button--gray {
    background-color: $dark-grey;
}

.Button--gray:hover {
    background-color: $light-black;
}

.Button--gray-light {
    background-color: $medium-grey;
    color: black;
}

.Button--twitchPurple {
    background-color: $twitch-purple;
}

.Button--twitchPurple:hover {
    background-color: $twitch-purple-dark;
}

.Button--gray-light:hover {
    background-color: $medium-dark-grey;
}

.Button--fill {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.Button--disabled {
    background-color: #d0d0d0;
    color: #7e7e7e;
    cursor: not-allowed! important;
}

.Button--disabled:hover {
    background-color: #d0d0d0;
    cursor: not-allowed! important;
}

.Button--loading {
    cursor: default;
    pointer-events: none;
}