@import '../../variables.scss';

.Error404Page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    color: $secondary-font-color;
}

.Error404Page__header {
    font-weight: bold;
    font-size: calc(3em + 1vw);
    line-height: calc(2em + 1vw);
    padding-bottom: 20px;
}

.Error404Page__subheader {
    text-align: center;
    padding-bottom: 40px;
}

.Error404Page__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Error404Page__button {
    padding-bottom: 20px;
}

.Error404Page__button:last-child {
    order: -1,
}

@media (min-width: 600px) {
    .Error404Page__buttons {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap: 30px;
    }

    .Error404Page__button {
        padding-bottom: 0;
    }

    .Error404Page__button:last-child {
        order: initial;
        grid-column-end: span 2;
        justify-self: center;
    }
}