.TitleBar {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.TitleBar__title {
    font-size: 22px;
    line-height: 24px;
    letter-spacing: .2rem;
    font-weight: 900;
    margin-bottom: 10px;
    text-align: center;
}

.TitleBar--h3 .TitleBar__title {
    font-size: 18px;
    margin-bottom: 0;
}

@media (min-width: 900px) {
    .TitleBar__title {
        font-size: 28px;
        letter-spacing: .3rem;
        line-height: 30px;
    }

    .TitleBar--h3 .TitleBar__title {
        font-size: 20px;
        letter-spacing: .2rem;
    }
}