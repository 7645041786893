.BlogPage {
    font-size: 18px;
}

.BlogPage img {
    max-width: 100%;
}

.BlogPage__socialButtons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
}

.BlogPage__tags {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px; 
}

.BlogPage__tag {
    padding-right: 10px;
}