@import '../../variables.scss';

.GuildNonMembersPage {
    padding: 20px 0 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.GuildNonMembersPage__content {
    max-width: 1200px;
    padding: 50px 20px 0;
}

.GuildNonMembersPage__content div {
    width: 100%;
    text-align: center;
}

.GuildNonMembersPage__header {
    font-size: $size-24-32;
    line-height: $size-26-34;
    padding-bottom: 30px;
}

.GuildNonMembersPage__description {
    padding-bottom: 20px;
    font-size: $size-20-24;
    line-height: $size-22-28;
}

.GuildNonMembersPage__cta {
    font-weight: bold;
    font-size: $size-24-32;
    line-height: $size-26-34;
}

.GuildNonMembersPage__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.GuildNonMembersPage__button {
    width: 300px;
    margin: 20px 40px;
}

.GuildNonMembersPage__button--centered {
    width: 100%;
    display: flex;
    justify-content: center;
}

.GuildNonMembersPage__cannot_create_display {
    margin-bottom: 20px;
}