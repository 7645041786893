@import "../../../variables.scss";

.GiftToModal {
    @include genericModalSetup()
}

.GiftToModal__header {
    font-size: 26px;
    line-height: 28px;
    color: $secondary-font-color;
    text-align: center;
    padding-bottom: 25px;
    font-weight: bold;
}

.GiftToModal__buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-top: 20px;
}

.GiftToModal__button {
    padding: 10px 0 0 10px;
}