.FaqContent__categoryBox {
    padding: 20px 0 40px;
}

.FaqContent__questionAnswer {
    padding: 18px 0;
    border-bottom: 1px solid rgb(150, 150, 150);
}

.FaqContent__question {
    color: black;
    padding-bottom: 10px;
    font-weight: bold;
}

.FaqContent__answer {
    color: black;
}