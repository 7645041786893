.GalleryRow {
	display: flex;
	justify-content: center;
	width: 100%;
	min-width: 1200px;
	margin: 15px 0;
}

.GalleryRow__item {
	width: 400px;
	padding: 10px;
}