@import '../../../variables.scss';

.Checkbox {
    font-size: 16px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
}

.Checkbox__label {
    padding-top: 2px;
    display: flex;
}

.Checkbox__required {
    color: red;
    padding-left: 3px;
}

.Checkbox__box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border-radius: 2px;
    border: 1px solid gray;
    background-color: $light-grey;
    cursor: pointer;
}

.Checkbox__checkMark {
    font-size: 22px;
    color: $secondary-font-color;
}

.Checkbox--labelLeft {
    flex-direction: row-reverse;

    .Checkbox__box {
        margin-right: 0;
        margin-left: 10px;
    }
}

.Checkbox--disabled {
    .Checkbox__box {
        background-color: $medium-grey;
        cursor: default;
    }
}