@import '../../../variables.scss';

.HelperLinks {
    width: 100%;
}

.HelperLinks__section {
    padding: 20px 0;
    border-top: 1px solid $light-med-grey;
}

.HelperLinks__section:last-child {
    border-bottom: 1px solid $light-med-grey;
    display: flex;
    justify-content: center;
}

.HelperLinks__header {
    font-weight: bold;
    padding-bottom: 10px;
    font-size: 1.07rem;
}

.HelperLinks__link {
    padding: 5px;
}