.swal-button {
    border-radius: 10px;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    padding: 12px 18px;
    line-height: 1;
    font-weight: 700;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.38);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: white;
}

.swal-button:focus {
    outline: none;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.38);
}

.swal-button--confirm, .swal-button--consume, .swal-button--ok, .swal-button--edit {
    color: white;
    background-color: #30556b;
}

.swal-button--loading {
    color: #30556b;
}

.swal-button--cancel, .swal-button--cancel:hover {
    background-color: #30556b;
}

.swal-button--confirm:hover, .swal-button:not([disabled]):hover {
    background-color: #30556b;
}

.swal-button--cancel {
    background-color: rgba(108, 145, 167, 1);
}

.swal-custom--gift-to-guild-member {
    margin: 30px auto 0;
    text-align: left;
    select {
        margin: 0 auto;
        width: 100%;
        height: 40px;
        padding: 0 10px;
        background-color: #f4f4f4;
        border: 1px solid #dedede;
        font-size: 16px;
        font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    }
}
