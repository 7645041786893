@import '../../../variables.scss';

.Masthead {
	position: relative;
	background-color: black;
	display: flex;
	justify-content: center;
}

.Masthead__buttons {
	display: grid;
	grid-template-columns: 100%;
	grid-gap: 20px;
}

.Masthead__buttons--single {
	grid-template-rows: 1fr;
}

.Masthead__overlay {
	position: absolute;
	padding: 60px 40px 40px;
	//height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	z-index: 10;
	justify-content: center;
}

.Masthead__overlayTop {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1600px;
	align-items: center;
	position: relative;
}

.MastHead__countdown {
	width: 340px;
	color: white;
	border-radius: 10px;
	padding: 15px 20px;
	background-color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: $standard-box-shadow;
	margin-top: 30px;
}

.MastHead__countdownHeader {
	text-align: center;
	font-size: 20px;
	padding-bottom: 10px;
	// color: #d48d31;
	// font-weight: bold;
	// filter: $h3-drop-shadow;
}

.MastHead__countdownTimer {
	font-size: 36px;
	letter-spacing: .6rem;
	font-weight: bold;
	height: 30px;
	display: flex;
	align-items: center;
}

.MastHead__countdownText {
	font-size: 12px;
	display: grid;
	grid-template-columns: repeat(4, 74px);
	text-align: center;
}

.Masthead__logo {
	position: relative;
	margin-bottom: 20px;
	max-width: 640px;
	img {
		max-height: 135px;
		max-width: 100%;
		filter: $h3-drop-shadow;
	}
}

.Masthead__text {
	padding-bottom: 5px;
}

.Masthead__subheader {
	color: #d48d31;
	margin-bottom: 15px;
	font-size: 24px;
	filter: $h3-drop-shadow;
}

.Masthead__media iframe {
	display: none;
}

.Masthead__description {
	position: relative;
	max-width: 800px;
	color: white;
	font-size: 20px;
	padding: 0 0 20px;
	line-height: 23px;
	text-align: center;
	filter: $h3-drop-shadow;
}

.Masthead__description-blur {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	@include BlurBackgroundEffect(50px);
}

.Masthead__subtext {
	color: #d48d31;
	font-size: 22px;
	font-weight: bold;
	padding-top: 5px;
	filter: $h3-drop-shadow;
}

.Masthead__starburst_display {
	display: none;
}
.Masthead__starburst_mobile_display {
	display: block;
}

.Masthead__starburst {
    position: absolute;
    right: 0px;
    top: 166px;
    z-index: 999;
    .Masthead__starburst_header {
        position: relative;
        .Masthead__starburst_phrase {
            position: absolute;
            color: black;
            font-weight: 900;
            //text-transform: uppercase;
            font-size: 22px;
            right: 96px;
            top: 30px;
        }

        .Masthead__starburst_header--grow {
            min-width: 80px;
            height: 80px;
        }
    }
}
.Masthead__starburstBody {
	position: absolute;
	right: 0px;
	top: 246px;
	z-index: 999;

	.Masthead__starburst_body_phrase {
		color: white;
		position: absolute;
		font-size: 18px;
		right: 96px;
		text-align: right;
		top: 13px;
		width: 200px;
	}

	img {
		height: 64px;
		max-width: 336px;
	}

	.Masthead__starburst_body--grow {
		min-width: 80px;
	}

	.Masthead__starburst_body--small {
		width: 20px;
	}
}

.Masthead__starburstFooter {
    position: absolute;
    right: 0px;
    top: 298px;
    z-index: 999;

    img {
        height: 4px;
    }

    .Masthead__starburst_body--grow {
        min-width: 80px;
    }

    .Masthead__starburst_body--small {
        width: 20px;
    }
}


@media(max-width: 999px) {
	.Masthead__starburst {
		position: absolute;
		right: 0px;
		top: 0;
		z-index: 999;
		width: 100%;
		.Masthead__starburst_header {
			display: flex;
			justify-content: center;
			top: -26px;
			font-size: 16px;
			font-weight: 900;
			.Masthead__starburst_phrase {
				color: black;
				font-weight: 900;
				text-transform: none;
				top: 0px;
				width: 100%;
				right: 0px;
				text-align: center;
				font-size: 16px;
			}
		}
	}
}

@media(min-width: 600px) {
.Masthead__overlayTop {
    // padding-top: 5%; <-- before addition of countdown clock
    padding-top: 2%;
}

.Masthead__buttons {
    grid-template-columns: repeat(2, 1fr);
}

.Masthead__buttons--single {
    grid-template-columns: 1fr;
}

.Masthead__text {
    display: block;
}
}

@media(min-width: 1000px) {
.Masthead__starburst_display {
    display: block;
}
.Masthead__starburst_mobile_display {
    display: none;
}
}


@media(min-width: 1200px) {
.Masthead__starburst_display {
    display: block;
}

.Masthead {
    display: block;
    max-height: 93vh;
    min-height: 525px;
}

.Masthead__overlayTop {
    min-height: 270px;
}

// .MastHead__countdown {
// 	position: absolute;
// 	right: 250px;
// 	top: -100px;
// }

.Masthead__featured {
    align-items: flex-start;
}

.Masthead__media {
    display: block;
    position: relative;
    width: 100%;
    //padding-top: 56.25%;
}

.Masthead__media iframe {
    display: block;
    //position: absolute;
    width: 100%;
    //height: 100%;
    top: 0;
    left: 0;
}

.Masthead__media img {
    display: none;
}
}

@media(min-width: 1600px) {
.Masthead__featured {
    bottom: 20%;
}
}


.MastHead__activateTrial {
	cursor: pointer;
}
