@import "../../../variables.scss";

.ECSTeamsPage {
    color: $primary-font-color;
    font-size: 18px;
    padding: 0 20px;
}

.ECSTeamsPage__title {
    padding-bottom: 30px;
    font-weight: bold;
    font-size: $size-28-38;
    text-align: center;
}

.ECSTeamsPage__tagline {
    text-align: center;
    margin-bottom: 30px;
}

.ECSTeamsPage__body {
    padding-bottom: 40px;
}

.ECSTeamsPage__event {
    padding-bottom: 30px;
}

.ECSTeamsPage__teams {
    padding: 20px 20px 0;
}

.ECSTeamsPage__team {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
}

.ECSTeamsPage__teamMembers {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 20px;
}


@media (min-width: 600px) {
    .ECSTeamsPage__team {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    .ECSTeamsPage__teamMembers {
        justify-content: flex-start;
        padding: 0 0 0 20px;
    }
}