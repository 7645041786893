@import "../../../../variables.scss";

.AlienwareLander {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    padding: 30px 20px 0;
}

.AlienwareLander__body {
    display:  flex;
    align-items: center;
    flex-direction: column;
}

.AlienwareLander__tagline {
    padding-bottom: 40px;
    max-width: 900px;
    text-align: center;
}

.AlienwareLander__box {
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
}

.AlienwareLander__title {
    width: 100%;
    font-weight: bold;
    font-size: 24px;
    padding: 30px 0 20px;
}

.AlienwareLander__gameLinks {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 15px;
    justify-items: flex-start;
    align-items: center;
}

.AlienwareLander__asset {
    width: 100%;
    max-width: 600px;
    padding-bottom: 30px;
}

.AlienwareLander__callout {
    width: 100%;
    padding: 0 10px 5px;
}

.AlienwareLander__header {
    display: flex;
    justify-content: center;
    font-size: 24px;
    padding-bottom: 25px;
}

.AlienwareLander__headerLeft {
    font-size: 24px;
    padding-bottom: 25px;
    width: 100%;
}

.AlienwareLander__text {
    width: 100%;
    padding-bottom: 10px;
    font-size: 18px;
}

@media (min-width: 1200px) {
    .AlienwareLander__body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px;
        align-items: flex-start;
    }
}