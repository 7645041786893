@import '../../../variables.scss';

.GuildMembersPage {
    color: $primary-font-color;
}

.GuildMembersPage__pendingInvites {
    padding: 20px 0 50px;
}

.GuildMembersPage__noInvitesText {
    font-size: 18px;
    text-align: center;
}

.GuildMembersPage__invitesList {

}

.GuildMembersPage__invite {
    border-top: 1px solid $secondary-font-color;
    padding: 20px;
    font-size: 16px;
    line-height: 24px;
}

.GuildMembersPage__invite:last-child {
    border-bottom: 1px solid $secondary-font-color;
}

.GuildMembersPage__inviteDate {
    padding-right: 25px;
    padding-bottom: 10px;
}

.GuildMembersPage__inviteRevokeButton {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 15px;
}

.GuildMembersPage__membersSection {
    padding: 20px 0 50px;
}

.GuildMembersPage__searchbar {
    padding-bottom: 20px;
}

.GuildMembersPage__table {
    padding: 10px 0 20px;
}

.GuildMembersPage__tableCallout {
    margin-top: 10px;
    font-size: 14px;
}

.GuildMembersPage__memberRanks {
    padding-top: 30px;
}

.GuildMembersPage__subsectionSpacer {
    height: 30px;
}

.GuildMembersPage__buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.GuildMembersPage__button {
    padding: 10px 0 0 10px;
}

.GuildMembersPage__memberLabel {
    display: flex;
}

.GuildMembersPage__asterisk {
    padding-top: 10px;
    font-size: 20px;
    font-weight: bold;
}


@media (min-width: 600px) {

    .GuildMembersPage__invite {
        display: grid;
        grid-template-columns: 160px 1fr auto;
        align-items: center;
    }

    .GuildMembersPage__inviteDate {
        padding-right: 15px;
        padding-bottom: 0;
    }

    .GuildMembersPage__inviteRevokeButton {
        padding-left: 15px;
        padding-top: 0;
        width: initial;
    }
}

@media (min-width: 900px) {
    .GuildMembersPage__invite {
        grid-template-columns: 200px 1fr auto;
    }
}