.GuildCrestPage {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.GuildCrestPage__preview {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.GuildCrestPage__crest {
	display: flex;
	align-items: baseline;
}

.GuildCrestPage__column {
	flex-grow: 1;
	flex-basis: 0;
	display: flex;
	flex-direction: column;
	display: none;
}

.GuildCrestPage__picker {
	margin-top: 15px;
	display: flex;
	.AssetPicker {
		align-items: center;
	}
}

.GuildCrestPage__guildSubtitle {
	color: gray;
	margin: 0;
}

.GuildCrestPage__controls {
	display: flex;
	justify-content: space-around;
	width: 100%;
	margin: 40px 0;
}

.GuildCrestPage__control {
	color: black;
	cursor: pointer;
}

.GuildCrestPage__mobile {
	width: 100%;
	margin: 15px  0;
	padding: 0 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.GuildCrestPage__asset {
	position: relative;
}

.GuildCrestPage__pattern {
	filter: invert(100%);
}

.GuildCrestPage__scroll {
	display: flex;
	justify-content: center;
	margin-top: 15px;
}

.GuildCrestPage__patternBg {
	position: absolute;
	top: 0;
	left: 0;
}

.GuildCrestPage__asset--icon {
	img {
		max-width: 110px;
	}
}

@media(min-width: 1200px) {
	.GuildCrestPage__mobile {
		display: none;
	}

	.GuildCrestPage {
		display: grid;
		grid-template-columns: 400px auto auto;
		align-items: flex-start;
	}

	.GuildCrestPage__preview {
		width: 400px;
		position: sticky;
		top: 66px;
	}

	.GuildCrestPage__picker {
		height: 290px;
		overflow-y: auto;
	}

	.GuildCrestPage__column {
		display: inline-block;
	}

	.GuildCrestPage__column1 {
		grid-column: 2;
	}

	.GuildCrestPage__column2 {
		grid-column: 3;
	}
}
