@import '../../../variables.scss';

.CheckoutReceipt {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 20px;
}

.CheckoutReceipt__title {
    width: 100%;
    text-align: center;
}

.CheckoutReceipt__subTitle {
    width: 100%;
    text-align: center;
    font-size: 18px;
    padding-bottom: 20px;
}

.CheckoutReceipt__marketingCTA {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    max-width: 1100px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CheckoutReceipt__marketingCTAText {
    max-width: 400px;
    font-weight: bold;
    color: white;
    padding: 10px 20px;
    z-index: 2;
    position: relative;
}

.CheckoutReceipt__marketingCTATextBlur {
    background: rgba(0, 0, 0, .6);
    -webkit-filter: blur(50px);
    filter: blur(50px);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}

.CheckoutReceipt__marketingCTAText div {
    color: white;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    line-height: 32px;
}

.CheckoutReceipt__marketingContent {
    padding: 20px 10px;
    width: 100%;
    max-width: 1200px;
}

.CheckoutReceipt__marketingContentBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.CheckoutReceipt__marketingContentImg {
    height: 222px;
    max-width: 500px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.CheckoutReceipt__marketingContentText {
    width: 100%;
    margin: 15px 0;
    padding: 0 10px;
    text-align: center;
}

.CheckoutReceipt__marketingContentTitle {
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
}

.CheckoutReceipt__marketingContentDescription {
    font-size: 16px;
    line-height: 18px;
    padding-top: 15px;
}

.CheckoutReceipt__marketingContentDescription p {
    padding: 4px 0;
}

.CheckoutReceipt__orderSummary {
    width: 100%;
    max-width: 1200px;
    margin-top: 40px;
}

.CheckoutReceipt__itemDetails {
    padding: 20px 0 10px;
    font-size: 20px;
    line-height: 22px;
    font-weight: bold;
}

.CheckoutReceipt__itemDetail {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
}

.CheckoutReceipt__item {
    padding-right: 20px;
}

.CheckoutReceipt__itemDescription {
    padding-top: 10px;
    font-size: 14px;
    font-weight: 400;
}

.CheckoutReceipt__itemPrice {
    white-space: nowrap;
}

.CheckoutReceipt__buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.CheckoutReceipt__ctaButton {
    padding: 10px;
}

.CheckoutReceipt__orderDetails {
    padding: 10px 0 20px;
}

.CheckoutReceipt__rightToWithdraw {
    width: 100%;
    display: flex;
    justify-content: center;
}

.CheckoutReceipt__rightToWithdrawText {
    padding: 10px 10px 0;
    max-width: 900px;
}

.CheckoutReceipt__socialText {
    width: 100%;
    text-align: center;
    padding: 0 15px;
    font-size: 24px;
}

.CheckoutReceipt__socialLinks {
    display: flex;
    justify-content: center;
    align-items: center;
}

.CheckoutReceipt__socialLink {
    padding: 0 5px;
    filter: $h3-drop-shadow
}

.CheckoutReceipt__termsAndConditions {
    max-width: 1000px;
    padding: 40px 10px 0;
    font-size: 12px;
}

.CheckoutReceipt__sub{
    font-size: 15px;
    color: rgb(224, 95, 95);
}

@media (min-width: 600px) {
    .CheckoutReceipt__marketingCTA {
        height: 500px;
    }

    .CheckoutReceipt__marketingCTAText {
        max-width: 510px;
    }

    .CheckoutReceipt__marketingCTAText div {
        font-size: 26px;
    }
    
    .CheckoutReceipt__marketingContentTitle {
        font-size: 22px;
        line-height: 24px;
    }

    .CheckoutReceipt__marketingContentDescription {
        font-size: 18px;
        line-height: 20px;
    }
}

@media (min-width: 900px) {
    .CheckoutReceipt__subTitle {
        font-size: 22px;
    }

    .CheckoutReceipt__marketingContentBox {
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
    }

    .CheckoutReceipt__marketingContentBox--reversed {
        flex-direction: row-reverse;
        padding-top: 20px;
    }

    .CheckoutReceipt__itemDetails {
        font-size: 26px;
        line-height: 28px;
    }

    .CheckoutReceipt__itemDescription {
        font-size: 18px;
    }
}