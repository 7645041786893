@import '../../../variables.scss';

.Radio {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.Radio__button {
	width: 26px;
	height: 26px;
	background-color: $light-grey;
	border: 1px solid $medium-grey;
	border-radius: 13px;
}

.Radio__check {
	width: 100%;
	height: 100%;
	background-color: $dark-grey;
	border-radius: 15px;
}

.Radio__label {
	font-size: 16px;
	padding: 0 15px 0 10px;
}

@media (min-width: 900px) {
	.Radio__button {
		width: 20px;
		height: 20px;
		border-radius: 10px;
	}

	.Radio__check {
		border-radius: 10px;
	}

	.Radio__label {
		font-size: 14px;
	}
}